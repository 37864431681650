import React, { useState } from 'react';
import ArrowIcon from '../../Icons/ArrowIcon';


const PaginationWidget = ({ totalPages, onPageChanged }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const changePage = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      onPageChanged(page);
    }
  };

  const buildPageNumbers = () => {
    const pageNumbers = [];
    const middleNumbersCount = 3;

    if (currentPage <= middleNumbersCount) {
      for (let i = 1; i <= middleNumbersCount; i++) {
        if (i > totalPages) break;
        pageNumbers.push(pageButton(i));
      }
      if (totalPages > middleNumbersCount + 1) {
        pageNumbers.push(<span key="ellipsis-1">...</span>);
        pageNumbers.push(pageButton(totalPages));
      }
    } else if (currentPage > middleNumbersCount && currentPage <= totalPages - middleNumbersCount) {
      pageNumbers.push(pageButton(1));
      pageNumbers.push(<span key="ellipsis-2">...</span>);

      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        pageNumbers.push(pageButton(i));
      }

      pageNumbers.push(<span key="ellipsis-3">...</span>);
      pageNumbers.push(pageButton(totalPages));
    } else {
      pageNumbers.push(pageButton(1));
      pageNumbers.push(<span key="ellipsis-4">...</span>);

      for (let i = totalPages - (middleNumbersCount - 1); i <= totalPages; i++) {
        pageNumbers.push(pageButton(i));
      }
    }

    return pageNumbers;
  };

  const pageButton = (page) => {
    const isActive = currentPage === page ? 'active' : ''
    return (
      <button className={`btnpagination ${isActive}`} key={page} onClick={() => changePage(page)}>
        {page}
      </button>
    );
  };

  return (
    <div className="pagination">
      <button className='btnpagination prevtbtn' onClick={() => changePage(currentPage - 1)} disabled={currentPage === 1} 
      style={{cursor: currentPage === 1 ? 'not-allowed' : 'pointer' }}      >
         <ArrowIcon/>
      </button>
      {buildPageNumbers()}
      <button
        onClick={() => changePage(currentPage + 1)}
        disabled={currentPage === totalPages}
        style={{cursor: currentPage === totalPages ? 'not-allowed' : 'pointer' }}
        className='btnpagination nextbtn'
      >
        <ArrowIcon/>
      </button>
    </div>
  );
};

export default PaginationWidget;

import React from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';


import IconDashbord from '../../Icons/IconDashbord';
import IconDish from '../../Icons/IconDish';
import IconGallery from '../../Icons/IconGallery';
import IconLoaction from '../../Icons/IconLoaction';
import IconMenuCard from '../../Icons/IconMenuCard';
import IconOrderLise from '../../Icons/IconOrderLise';
import Logo from '../../Icons/Logo';
import PhoneIcon from '../../Icons/PhoneIcon';
import './style.css';

export default function Sidebar({ isOpen }) {
  const location = useLocation();
  
  // Determine which menu item is active based on the current path
  const isActive = (paths) => {
    return paths.some(path => matchPath({ path, end: false }, location.pathname));
  };

  return (
    <div className={`sidebar ${isOpen ? 'CloseMenu' : ''}`}>
      <div className='logosidebar'>
        <Logo/>
      </div>
      <div className='left-sidemneu'>
        <ul>
          <li className={isActive(['/dashboard']) ? 'active' : ''}>
            <Link to="/dashboard">
              <IconDashbord /> Dashboard
            </Link>
          </li>
          <li className={isActive(['/manage-regular-categories']) ? 'active' : ''}>
            <Link to="/manage-regular-categories">
            <IconDish/> Regular Menu Categories
            </Link>
          </li>   

          <li className={isActive(['/manage-regular-dishes']) ? 'active' : ''}>
            <Link to="/manage-regular-dishes">
              <IconDish/>Regular Menu Dish
            </Link>
          </li>   

          <li className={isActive(['/manage-categories']) ? 'active' : ''}>
            <Link to="/manage-categories">
            <IconMenuCard/>  Add Catering Menu Categories
            </Link>
          </li>   

          <li className={isActive(['/add-dishes']) ? 'active' : ''}>
            <Link to="/add-dishes">
              <IconMenuCard/> Add Catering Dish 
            </Link>
          </li>   
       

          <li className={isActive(['/add-location']) ? 'active' : ''}>
            <Link to="/add-location">
              <IconLoaction/> Manage Location
            </Link>
          </li>                   
          <li className={isActive(['/add-gallery']) ? 'active' : ''}>
            <Link to="/add-gallery">
              <IconGallery/> Manage Gallery
            </Link>
          </li>
          <li className={isActive(['/contact-list']) ? 'active' : ''}>
            <Link to="/contact-list">
              <PhoneIcon/>Contact us List
            </Link>
          </li>
          <li className={isActive(['/preorder-list']) ? 'active' : ''}>
            <Link to="/Preorder-list">
              <IconOrderLise/>Preorder List
            </Link>
          </li>

        </ul>
      </div>
    </div>
  );
}

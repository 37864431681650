import React from "react";
import "./style.css";
import HomeBG from "../Icons/HomeBG";

export default function SliderHome({loading}) {
  return (
    <section className=" homeslider">
      {!loading && <HomeBG loading={loading} />}
      <div className="bghome"></div>
      <div className="w-full relative z-10">
        <div className="section slidercontent">
      <div className="container">
        <div className="sliderinfo">         
            <h2 className="font-bold animate-fadeZoomUp delay-[200ms]">Welcome to</h2>
          <h1 className="font-bold animate-fadeZoomUp delay-[200ms]">            
             Spice on Wheels!
          </h1>
          <p className="animate-fadeZoomUp delay-[400ms]">
            Come and experience the best in world-class cuisine, food, and
            catering. Indulge in flavors that delight your senses and leave
            lasting memories. We bring exceptional taste and quality to every
            meal we serve. Your ultimate culinary experience begins with us!
          </p>
        </div>      
      </div>
      </div>
      <div className="homedownbg"></div>
      </div>
    </section>
  );
}

import React from "react";

export default function IconDashbord() {
  return (
    <svg
      id="Data_Analysis"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m29 44h-23c-1.1 0-2-.9-2-2v-34c0-1.1.9-2 2-2h49c1.1 0 2 .9 2 2v22.76c0 .55.45 1 1 1s1-.45 1-1v-22.76c0-2.21-1.79-4-4-4h-49c-2.21 0-4 1.79-4 4v34c0 2.21 1.79 4 4 4h23c.55 0 1-.45 1-1s-.45-1-1-1z" />
      <path d="m62 45c0-8.27-6.73-15-15-15s-15 6.73-15 15 6.73 15 15 15c4 0 7.76-1.56 10.61-4.39s0 0 0 0c2.83-2.84 4.39-6.61 4.39-10.61zm-5.13 8.46-2.84-2.84c1.07-1.34 1.73-2.93 1.91-4.62h4.02c-.21 2.76-1.28 5.35-3.09 7.46zm-9.87-15.46c3.86 0 7 3.14 7 7 0 1.86-.73 3.62-2.05 4.95-1.33 1.32-3.09 2.05-4.95 2.05-3.86 0-7-3.14-7-7s3.14-7 7-7zm12.96 6h-4.02c-.46-4.17-3.78-7.48-7.94-7.94v-4.02c6.37.49 11.47 5.59 11.96 11.96zm-12.96 14c-7.17 0-13-5.83-13-13s5.3-12.45 12-12.96v4.02c-4.49.5-8 4.32-8 8.94 0 4.96 4.04 9 9 9 2.06 0 4.02-.69 5.62-1.97l2.84 2.84c-2.36 2.02-5.33 3.13-8.46 3.13z" />
      <path d="m33 20c0-.53-.15-1.03-.4-1.46l3.29-3.76c.34.14.71.22 1.1.22s.76-.08 1.1-.22l3.29 3.76c-.24.43-.4.93-.4 1.46 0 1.65 1.35 3 3 3s3-1.35 3-3c0-.53-.15-1.03-.4-1.46l3.29-3.76c.34.14.71.22 1.1.22 1.65 0 3-1.35 3-3s-1.35-3-3-3-3 1.35-3 3c0 .53.15 1.03.4 1.46l-3.29 3.76c-.34-.14-.71-.22-1.1-.22s-.76.08-1.1.22l-3.29-3.76c.24-.43.4-.93.4-1.46 0-1.65-1.35-3-3-3s-3 1.35-3 3c0 .53.15 1.03.4 1.46l-3.29 3.76c-.34-.14-.71-.22-1.1-.22-1.65 0-3 1.35-3 3s1.35 3 3 3 3-1.35 3-3zm18-9c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-6 9c0 .55-.45 1-1 1s-1-.45-1-1 .45-1 1-1 1 .45 1 1zm-8-9c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-7 10c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z" />
      <path d="m7 40c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-11c0-.55-.45-1-1-1h-4c-.55 0-1 .45-1 1zm2-10h2v9h-2z" />
      <path d="m16 24c-.55 0-1 .45-1 1v15c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-15c0-.55-.45-1-1-1zm3 15h-2v-13h2z" />
      <path d="m24 41h4c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-4c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zm1-8h2v6h-2z" />
      <path d="m8 11h2c.55 0 1-.45 1-1s-.45-1-1-1h-2c-.55 0-1 .45-1 1s.45 1 1 1z" />
      <path d="m14 11h8c.55 0 1-.45 1-1s-.45-1-1-1h-8c-.55 0-1 .45-1 1s.45 1 1 1z" />
      <path d="m8 16h2c.55 0 1-.45 1-1s-.45-1-1-1h-2c-.55 0-1 .45-1 1s.45 1 1 1z" />
      <path d="m14 16h8c.55 0 1-.45 1-1s-.45-1-1-1h-8c-.55 0-1 .45-1 1s.45 1 1 1z" />
      <path d="m8 21h2c.55 0 1-.45 1-1s-.45-1-1-1h-2c-.55 0-1 .45-1 1s.45 1 1 1z" />
      <path d="m23 20c0-.55-.45-1-1-1h-8c-.55 0-1 .45-1 1s.45 1 1 1h8c.55 0 1-.45 1-1z" />
    </svg>
  );
}

import React, { useEffect, useState } from "react";
import PageLayout from "../../component/PageLayout";
import api from "../../services/api";
import "./style.css";

export default function Catering() {
  const [categories, setCategories] = useState([]);
  const [dishes, setDishes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoriesResponse, dishesResponse] = await Promise.all([
          api.post("/list_categories", {}, { headers: { "Content-Type": "application/json" } }),
          api.post("/listdishes", {}, { headers: { "Content-Type": "application/json" } })
        ]);

        const categoriesData = categoriesResponse.data.data || [];
        const dishesData = dishesResponse.data.data || [];

        setCategories(categoriesData);
        setDishes(dishesData);
        setLoading(false);
      } catch (err) {
        setError("Error fetching menu data");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Merge categories with their respective dishes
  const groupedMenuData = categories.map((category) => ({
    ...category,
    dishes: dishes.filter((dish) => dish.Category === category.Category)
  }))
  // .filter(category => category.dishes.length > 0);

  return (
    <PageLayout>
      <section className="section food-menu-main ptinner">
      <div className="titleone text-center">
            <h4>Catering Menu List</h4>
          </div>
        <div className="container">        
          <div className="catering-menu">
            {loading ? (
              <div className="loading-indicator text-center py-32">Loading...</div>
            ) : error ? (
              <div className="error-message text-center py-32">{error}</div>
            ) : groupedMenuData.length > 0 ? (
              groupedMenuData.map((category, index) => (
                <div key={index} className="menulist">
                  <div className={category.dishes.length > 0 ? "menutitle" : "menutitle Nonvage"}>
                    <h3>{category.Category}</h3>
                    <p>{category.Description}</p>
                  </div>
                  {/* Show <ul> only if there are dishes */}
                  {category.dishes.length > 0 && (
                    <ul className="categary-name">
                      {category.dishes.map((dish, dishIndex) => (
                        <li key={dishIndex}>{dish.Dish_Name}</li>
                      ))}
                    </ul>
                  )}
                </div>
              ))
            ) : (
              <div className="text-center py-32">No menu data available</div>
            )}
          </div>
        </div>
      </section>
    </PageLayout>
  );
}

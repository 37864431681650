import React, { useEffect, useState } from "react";
import "./style.css";
import { NavLink } from "react-router-dom";
import LocationIcon from "../Icons/LocationIcon";
import IconHom from "../Icons/IconHom";
import IconMenuClose from "../Icons/IconMenuClose";

export default function PhoneMenu() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    if (menuOpen) {
      document.documentElement.classList.add("hidescroll");
    } else {
      document.documentElement.classList.remove("hidescroll");
    }
    return () => {
      document.documentElement.classList.remove("hidescroll");
    };
  }, [menuOpen]);

  const openMap = () => {
    const location = "37.7749,-122.4194";
    window.open(`https://www.google.com/maps?q=${location}`, "_blank");
  };

  return (
        <div className="headermenu">
          <div className="pgonelogo">
          <NavLink to="/">
            <img src="../images/logo.svg" alt="Spice on Wheel" />
          </NavLink>
          </div>

          <div className="headerright">
            <strong onClick={openMap} style={{ cursor: "pointer" }}>
              <LocationIcon/>              
            </strong>
          <div
            className={`hideshowbar ${menuOpen ? "rotate-top" : ""}`}
            onClick={toggleMenu}
          > 
          <span><IconHom/></span>
          <label><IconMenuClose/></label>
          </div>
          </div>

          <nav className={`desktopmenu ${menuOpen ? "open" : ""}`}>
            <ul>
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) => (isActive ? "active" : "")}
                  end
                >
                  Home
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/menu"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Menu
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/gallery"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Gallery
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/location"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Location
                </NavLink>
              </li>
              <li className="logo">
                <NavLink
                  to="/"
                  className={({ isActive }) => (isActive ? "active" : "")}
                  end
                >
                  <img src="../images/logo.svg" alt="Spice on Wheel" />
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/catering"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Catering
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/pre-order"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Pre-Order
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about-us"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact-us"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      
  );
}

import React from "react";
import PageLayout from "../../component/PageLayout";

export default function Location() {
  return (
    <section className="todayloaction-main">
    <PageLayout>
      
        {/* <div className="container">
        <div className="titleone text-center">
        <h4>Today Live Location</h4>
      </div>
        <div className="todayloaction">
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d29374.80925010389!2d72.5778432!3d23.029236700000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1737524661486!5m2!1sen!2sin" 
      allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
      </div>       */}
    </PageLayout>
    </section>
  );
}

import React, { useEffect, useState } from "react";
import AdminHeader from "../../../../component/AdminComponent/AdminHeader";
import IconClose from "../../../../component/Icons/IconClose";
import api from "../../../../services/api";
import { useToast } from "../../../../services/Toster";
import PaginationWidget from "../../../../component/AdminComponent/PaginationWidget";

export default function RegularCategories() {
  const initialFormData = {
    Category: "",
    DishQuality: "",
  };
  const notify = useToast();
  const [menuItems, setMenuItems] = useState([]);
  const [formData, setFormData] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const handleResize = () => {
    if (window.innerWidth <= 1450) {
      setItemsPerPage(10);
    } else {
      setItemsPerPage(20);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchData = async () => {
    try {
      const response = await api.post(
        "/list_RegularCategories",
        {},
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.data && Array.isArray(response.data.data)) {
        const reversedItems = response.data.data.reverse();
        setMenuItems(reversedItems);
        setTotalPages(Math.ceil(reversedItems.length / itemsPerPage));
      } else {
        // console.error("Expected an array but got:", response.data);
        notify("error", "Expected an array but got:", response.data);
        setMenuItems([]);
      }
    } catch (error) {
      //console.error("Error fetching data:", error);
      notify("error", "Failed to fetch RegularCategories. Please try again.");
      setMenuItems([]);
    }
  };
  useEffect(() => {
    fetchData();
  }, [itemsPerPage]);

  const paginateItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return menuItems.slice(startIndex, endIndex);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleQuillChange = (event) => {
    setFormData((prevState) => ({
      ...prevState,
      DishQuality: event.target.value,
    }));
  };

  // ✅ Add category and fetch updated list
  const handleAddItem = async () => {
    if (!formData.Category) {
      notify("error", "Category name is required.");
      return;
    }
    const isDuplicate = menuItems.some(
      (item) => item.Category === formData.Category
    );

    if (isDuplicate) {
      notify("error", "Category already exists.");
      return;
    }

    setIsLoading(true);
    try {
      const _newCategory = {
        ...formData,
        Date_Time_Stamp: new Date(),
      };

      const response = await api.post("/create_RegularCategories", _newCategory, {
        headers: { "Content-Type": "application/json" },
      });
      console.log("response response response", response);

      if (response.status === 201 && response.data) {
        fetchData();
        setFormData(initialFormData);
        notify("success", "Category added successfully");
      } else {
        // console.error("Error: API did not return expected data.", response.data);
        notify(
          "error",
          "Failed to save category. Please try again.",
          response.data
        );
      }
    } catch (error) {
      console.error("Error adding category:", error);
      notify("error", "Error adding category. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveItem = async (index, categoryId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this category?"
    );
    if (!confirmDelete) return;

    try {
      const response = await api.post(
        "/delete_category",
        { id: categoryId },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        fetchData(); // Refresh list after deletion
        notify("success", "Category deleted successfully.");
      } else {
        // console.error("Failed to delete category");
        notify("error", "Failed to delete category.");
      }
    } catch (error) {
      // console.error("Error deleting category:", error);
      notify("error", "Error deleting category.", error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <section className="dashboardpage">
      <AdminHeader pageTitle="Regular Menu Categories" />
      <div className="pageLeftspace">
        <div className="form-fields">
          <form>
            <div className="grid-container">
              <div className="grid-item">
                <label>Category Name <sup>*</sup></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Category Name"
                  name="Category"
                  value={formData.Category}
                  onChange={handleInputChange}
                />
              </div>

              <div className="grid-item">
                <label>Category Quality</label>
                <input
                  name="Description"
                  placeholder="Enter Description"
                  className="form-control"
                  value={formData.DishQuality}
                  onChange={handleQuillChange}
                />
              </div>

              <button
                className="btn"
                type="button"
                onClick={handleAddItem}
                disabled={isLoading}
              >
                {isLoading ? "Save Item..." : "Save Item"}
              </button>
            </div>
          </form>

          <div className="menuItems">
            {Array.isArray(menuItems) && menuItems.length > 0 ? (
              paginateItems().map((item, index) => (
                <div className="dishname" key={item.ID || index}>
                  <IconClose onClick={() => handleRemoveItem(index, item.ID)} />
                  <h4>{item.Category}</h4>
                  <div className="description">
                    {item.DishQuality && (
                      <div
                        className="textariabox"
                        dangerouslySetInnerHTML={{ __html: item.DishQuality }}
                      />
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p>No RegularCategories available.</p>
            )}
          </div>

          {menuItems.length > itemsPerPage && (
            <PaginationWidget
              totalPages={totalPages}
              onPageChanged={handlePageChange}
            />
          )}
        </div>
      </div>
    </section>
  );
}

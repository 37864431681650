import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import PageLayout from "../../component/PageLayout";
import "./style.css";
import api from "../../services/api";
import { useToast } from "../../services/Toster";

export default function PreOrder() {  
  const initialFormData = {
    Customer_Email_ID: "",
    Customer_Mob_No: "",
    Cutomer_Name: "",
    Customer_Address: "",
    Customer_Remarks: "",
    selectedItems: [],
  };
  const [formData, setFormData] = useState(initialFormData); 
  const [options, setOptions] = useState([]); 
  const [isFetchingOptions, setIsFetchingOptions] = useState(true);  
  const [isLoading, setIsLoading] = useState(false);  
  const notify = useToast();

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await api.post(
          "/list_regular_dishes", 
          {},
          { headers: { "Content-Type": "application/json" } }
        );
  
        // console.log("response", response.data.data);
  
        if (response.data.data) {
          const fetchedOptions = response.data.data.map((item) => ({
            label: item.Dish_Name, 
            value: item.Dish_Name,  
          }));
          setOptions(fetchedOptions);
        } 
        // else {
        //   notify("error", "Failed to fetch options.");
        // }
      } catch (error) {
        console.error("error", "Error fetching options. Please try again later.");
      } finally {
        setIsFetchingOptions(false);
      }
    };
  
    fetchOptions();
  }, [notify]);
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSelectChange = (selected) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedItems: selected.map((item) => item.value),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await api.post('/create_preorder', formData);    
      console.log("Thank you", response.data.data);  
      if (response.data) {
        notify("success", "Thank you for reaching out! Your Pre-order has been received, and our team will get back to you shortly.");
        setFormData(initialFormData);
      } 
      else {
        notify("error", response.data.message);
      }
    } catch (error) {
      notify("error",'Failed to send email. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <PageLayout>     
      <section className="section ptinner preorder">
      <div className="titleone text-center">
            <h4>Pre Order Now</h4>
          </div>
          <div className="bigscreen pagewidth">
        <div className="container"> 
          <div className="bookform">
            <div className="bookform-left">
              <form onSubmit={handleSubmit}>
              <div className="form_input">
                  <label htmlFor="Cutomer_Name">
                    Your Name <small>*</small>
                  </label>
                  <input
                    className="inputbox"
                    type="text"
                    placeholder="Your Name"
                    name="Cutomer_Name"
                    value={formData.Cutomer_Name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form_input">
                  <label htmlFor="Customer_Mob_No">
                    Contact No.<small>*</small>
                  </label>
                  <input
                    className="inputbox"
                    type="text"
                    placeholder="Contact No"
                    name="Customer_Mob_No"
                    value={formData.Customer_Mob_No}
                    onChange={handleChange}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, ""); 
                    }}
                    onBlur={(e) => {
                      if (e.target.value.length < 10) {
                        notify("error", "Contact number must be at least 10 digits.");
                      }
                    }}
                    required
                  />
                </div>
                <div className="form_input">
                  <label htmlFor="Customer_Email_ID">
                    Your Email <small>*</small>
                  </label>
                  <input
                    className="inputbox"
                    type="email"
                    placeholder="Your Email"
                    name="Customer_Email_ID"
                    value={formData.Customer_Email_ID}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form_input">
                  <label htmlFor="Customer_Address">
                    Address <small>*</small>
                  </label>
                  <input
                    className="inputbox"
                    type="text"
                    placeholder="Enter address"
                    name="Customer_Address"
                    value={formData.Customer_Address}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form_input">
                  <label htmlFor="selectedItems">Select Item</label>
                  <MultiSelect
                    options={options}
                    value={options.filter((option) =>
                      formData.selectedItems.includes(option.value)
                    )}
                    onChange={handleSelectChange}
                    labelledBy="Select"
                    disabled={isFetchingOptions}
                    // isOpen={true}
                    hasSelectAll={false}
                  />
                </div>
                <div className="textarea">
                  <label htmlFor="Customer_Remarks">Enter Your Message and Quantity</label>
                  <textarea
                    rows={4}
                    name="Customer_Remarks"
                    placeholder="Enter Your Message and Quantity Here.."
                    value={formData.Customer_Remarks}
                    onChange={handleChange}
                  ></textarea>
                </div>

                  <div className="form-btn">
                  <button type="submit" className="btn submitForm" disabled={isLoading}>
                  {isLoading ? "Sending..." : "Send"}
                </button>
                  </div>
              </form>
            </div>
         
          </div>
        </div>
        </div>
      </section>
    </PageLayout>
  );
}

import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import AdminHeader from "../../../../component/AdminComponent/AdminHeader";
import IconGallery from "../../../../component/Icons/IconGallery";
import api from "../../../../services/api";
import { useToast } from "../../../../services/Toster";
import './style.css';
import IconClose from "../../../../component/Icons/IconClose";
import PaginationWidget from "../../../../component/AdminComponent/PaginationWidget";

export default function AddGallery() {
  const notify = useToast();
  const [dishName, setDishName] = useState();
  const [formattedData, setFormattedData] = useState([]); 
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 8

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const response = await api.post("/List_Image_Gallery", {}, {
          headers: { "Content-Type": "application/json" },
        });
        if (isMounted) {
          const reversedItems = response.data.data.reverse();
          setFormattedData(reversedItems);           
          const pages = Math.ceil(reversedItems.length / itemsPerPage); 
   
          setTotalPages(pages);          
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  const paginateItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return formattedData.slice(startIndex, endIndex);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name == 'dishName') {
      setDishName(value);
    }
  };

  const handleAddGalleryImageChange = (e) => {
    const file = e.target.files[0];
    if (!dishName) {
      notify('error', `Dish name is required!`);
      return;
    }
    if (!file) return;

    const newImages = [];
    const largeImages = [];
    const invalidImages = [];
    const validExtensions = ['jpg', 'jpeg', 'png', 'webp', 'gif'];
    Array.from(e.target.files).forEach(file => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (validExtensions.includes(fileExtension)) {
        if (file.size <= 5242880) { // 1 MB = 1048576 bytes
          newImages.push({
            file,
            url: URL.createObjectURL(file),
            name: file.name,
          });
        } else {
          largeImages.push(file.name);
        }
      } else {
        invalidImages.push(file.name);
      }
    });

    if (largeImages.length > 0) {
      notify('error', `${largeImages.join(', ')} exceed(s) the 5 MB size limit.`);
    }

    if (invalidImages.length > 0) {
      notify('error', `${invalidImages.join(', ')} file type(s) are not allowed.`);
    }

    setImages(newImages); // Update state with new images


  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!dishName) {
      notify("error", "Dish name is required!");
      return;
    }

    if (images.length === 0) {
      notify("error", "Please select at least one image.");
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("dishName", dishName);
    images.forEach((image) => {
      formData.append("image", image.file); // Append each image file
    });

    try {
      const response = await api.post("/create_image_gallery", formData, {
        headers: {
          "Content-Type": "multipart/form-data", 
        },
      });

      if (response.data.success) {
        notify("success", response.data.message);
        setDishName("");
        setImages([]);
        const newGalleryData = await api.post('/List_Image_Gallery');
        setFormattedData(newGalleryData.data.data);

      } else {
        notify("error", response.data.message);
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      notify("error", "An error occurred while uploading images.");
    }finally {
      setIsLoading(false); 
    }
  };


  const handleRemoveItem = async (index, imageID) => {
    console.log("Delete clicked for index:", index, "Image ID:", imageID); // Debugging
  
    // Show confirmation dialog
    const confirmDelete = window.confirm("Are you sure you want to delete this image?");
    if (!confirmDelete) return; // Exit if user cancels
  
    try {
      // Call the delete API
      const response = await api.post("/delete_image_gallery", { id: imageID }, {
        headers: { "Content-Type": "application/json" },
      });
  
      if (response.status === 200) {
        console.log("Image deleted successfully, updating UI");
  
        // Update state by filtering out the deleted item
        setFormattedData((prevItems) => prevItems.filter((item) => item.ID !== imageID));
      } else {
        console.error("Failed to delete image");
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };
  
  const currentData = paginateItems();

  return (
    <section className='gallerypage'>
      <AdminHeader pageTitle="Add Gallery Pictures" />
      <div className='pageLeftspace'>
        <div className='tableheader d-flex justify-content-between'>
          <form className='galleryform' onSubmit={handleSubmit}>

            <div className="form-group">
              <label>Enter Dish Name <sup>*</sup></label>
              <input
                type="text"
                className="form-control"
                placeholder="Dish Name"
                value={dishName}
                name="dishName"
                onChange={handleInputChange}

              />
            </div>

            <div className="form-group">
              <div className="gallery-item">
                <label><span>Upload Picture <sup>*</sup></span> <IconGallery /></label>
                <input
                  className="form-control"
                  type="file"
                  name="files"
                  id="importFile"
                  onClick={(e) => (e.target.value = "")}
                  onChange={handleAddGalleryImageChange}
                  accept=".png,.jpg,.jpeg,.webp"
                />

                <Link className='fileupload' to="#">Browser File</Link>
                <small>1 Mb file upload</small>
              </div>
            </div>
            <button className="btn" type="submit" disabled={isLoading}>
              {isLoading ? "Saving..." : "Save"}
            </button>
          </form>

          <div className='pictures-addshow'>
          {currentData.map((pic, index) => (
            <div className="gallerypicadd" key={pic.ID}>
              <IconClose onClick={() => handleRemoveItem(index,pic.ID)} />
              <div className="imagborder">
                {/* <img src={`http://localhost:3001${pic.Image_Path}`} alt={`Gallery item ${pic.ID}`} /> */}
                <img src={`${process.env.REACT_APP_API_PRJ_URL}${pic.Image_Path}`} alt={`Gallery item ${pic.ID}`} />
              </div>
              <p>{pic.Image_Title}</p>
            </div>
          ))}
        </div>

        {formattedData.length > itemsPerPage && (
            <PaginationWidget totalPages={totalPages} onPageChanged={handlePageChange} />
          )}

        </div>

       
      </div>
    </section>
  );
}

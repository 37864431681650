import React, { useState, useRef, useEffect } from "react";
import AdminHeader from "../../../../component/AdminComponent/AdminHeader";
import "./style.css";
import api from "../../../../services/api";
import { useToast } from "../../../../services/Toster";
import { GoogleMap, Marker, StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";


const libraries = ["places"]; // Ensure the "places" library is loaded

export default function AddLocation() {
  const initialFormData = {
    Date_From: "",
    Time_From: "",
    Time_To: "",
    Latitude: "",
    Longitude: "",
  };

  const notify = useToast();
  const [formData, setFormData] = useState(initialFormData);
  const [mapCenter, setMapCenter] = useState({ lat: 23.0292366, lng: 72.5778432 });
  const [searchText, setSearchText] = useState("");
  const searchBoxRef = useRef(null);
   const [mapKey, setMapKey] = useState(0);
  console.log("Rendering Marker at:", mapCenter);

  // Load Google Maps API only once
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDVwmhfEaJg-hWXo24p04WFjJNAPfF0Cz4", 
    libraries
  });

  if (loadError) console.error("Google Maps API failed to load:", loadError);
  
  // Handle input changes for Date, Time, Latitude, and Longitude
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "Latitude" || name === "Longitude") {
      const lat = parseFloat(name === "Latitude" ? value : formData.Latitude) || 0;
      const lng = parseFloat(name === "Longitude" ? value : formData.Longitude) || 0;
      setMapCenter({ lat, lng });
    }
  };
  const forceRerender = () => {
    setMapKey((prevKey) => prevKey + 1);
  };
  
  useEffect(() => {
    forceRerender();
  }, [mapCenter]);
  // Handle marker drag event



  const handlePlaceSelect = () => {
    try {
      const places = searchBoxRef.current.getPlaces();
      if (!places || places.length === 0) {
        notify("error", "No places found. Please try again.");
        return;
      }
  
      const place = places[0];
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
  
      console.log("Place selected:", place.formatted_address, { lat, lng });
  
      // Update both mapCenter and formData
      setMapCenter({ lat, lng });
      setFormData({ ...formData, Latitude: lat, Longitude: lng });
  
      // Update the search text for UI feedback
      setSearchText(place.formatted_address);
    } catch (error) {
      console.error("Error in handlePlaceSelect:", error);
      notify("error", "Unable to select the place. Please try again.");
    }
  };

  
  const handleMarkerDragEnd = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    console.log("New marker position:", { lat, lng });
    setFormData({ ...formData, Latitude: lat, Longitude: lng });
    // setMapCenter({ lat, lng });
    handlePlaceSelect();
  };
  // Handle place selection from Google Places Autocomplete
 

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.Date_From || !formData.Time_From || !formData.Time_To || !formData.Latitude || !formData.Longitude) {
      notify("error", "Please fill in all required fields!");
      return;
    }

    const submissionData = {
      Date_Time_From: `${formData.Date_From} ${formData.Time_From}:00`,
      Date_Time_To: `${formData.Date_From} ${formData.Time_To}:00`,
      Latitude: formData.Latitude,
      Longitude: formData.Longitude,
    };

    try {
      const response = await api.post("/locations", submissionData, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.data) {
        notify("success", "Location saved successfully.");
        setFormData(initialFormData);
      } else {
        notify("error", "Some exception has occurred. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      notify("error", "An error occurred while submitting the form.");
    }
  };


  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          if (latitude !== mapCenter.lat || longitude !== mapCenter.lng) {
            setMapCenter({ lat: latitude, lng: longitude });
            setFormData({ ...formData, Latitude: latitude, Longitude: longitude });
          }
        },
        (error) => console.error("Error fetching location:", error)
      );
    }
  }, []);

  return (
    <section className="locationpage">
      <AdminHeader pageTitle="Manage Locations" />
      <div className="pageLeftspace">
        <div className="tableheader flex-column gap-4 d-flex justify-content-between align-items-center">
          <form className="locationform" onSubmit={handleSubmit}>
            <div className="w-full flex gap-5">
            <div className="form-group">
              <label>Select Date</label>
              <input type="date" className="form-control" name="Date_From" value={formData.Date_From} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Start Time</label>
              <input type="time" className="form-control" name="Time_From" value={formData.Time_From} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>End Time</label>
              <input type="time" className="form-control" name="Time_To" value={formData.Time_To} onChange={handleInputChange} />
            </div>
            </div>
            <div className="w-full flex gap-5 align-items-end">

            {isLoaded && (
              <div className="form-group locationsearch">
                <label>Search Location</label>
                <StandaloneSearchBox   
                onLoad={(ref) => {
                    searchBoxRef.current = ref;
                    console.log("SearchBox Loaded:", ref);
                  }}
                  onPlacesChanged={handlePlaceSelect}>
                  <input
                    type="text"
                    placeholder="Search Location"
                    className="form-control"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </StandaloneSearchBox>
              </div>
            )}

            <div className="form-group">
              <label>Latitude</label>
              <input type="text" className="form-control" name="Latitude" value={formData.Latitude} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Longitude</label>
              <input type="text" className="form-control" name="Longitude" value={formData.Longitude} onChange={handleInputChange} />
            </div>

            <button className="btn" type="submit"> Save Location </button>
            </div>
          </form>

          {/* Google Map Display */}
          {/* {isLoaded && (
            <div className="showLocation">
              <GoogleMap mapContainerStyle={{ height: "400px", width: "100%" }} center={mapCenter} zoom={12}>
                <Marker zIndex={999} position={mapCenter} draggable={true} onDragEnd={handleMarkerDragEnd} />
              </GoogleMap>
            </div>
          )} */}
          {isLoaded && (
            <div className="showLocation">
              <GoogleMap   key={mapKey} mapContainerStyle={{ height: "400px", width: "100%" }} center={mapCenter} zoom={15}>
                 {console.log("Rendering Map with Center:", mapCenter)}
                <Marker position={mapCenter} draggable={true} onDragEnd={handleMarkerDragEnd} />
              </GoogleMap>
            </div>
          )}

        </div>
      </div>
    </section>
  );
}


import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import IconKey from "../../../../component/Icons/IconKey";
import api from "../../../../services/api";
import { useToast } from "../../../../services/Toster";
import IconLock from "../../../../component/Icons/IconLock";
import IconSignIn from "../../../../component/Icons/IconSignIn";
import Logo from "../../../../component/Icons/Logo";
import "./style.css";
import IconShowEye from "../../../../component/Icons/IconShowEye";

export default function Login() {
  const notify = useToast();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);
  
    try {
      const response = await api.post(
        "/login",
        { identifier: username, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 200) {
        const { role, Name, UserId, UserEmailId, UserMobile } = response.data;
        localStorage.setItem("authToken", response.data.token);
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("role", role);
        localStorage.setItem("UserId", UserId);
        localStorage.setItem("Name", Name);
        localStorage.setItem("UserEmailId", UserEmailId);
        localStorage.setItem("UserMobile", UserMobile);
        notify("success", "Login successful");
        if (role === "Admin") {
          navigate("/dashboard");
        } 
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        notify("error", error.response.data.message);
      } else if (error.request) {
        console.log("Request made but no response:", error.request);
        if (error.code === "ERR_NETWORK") {
          notify("error", "Network error. Please try again later.");
        } else {
          notify("error", "An error occurred. Please try again.");
        }
      } else {
        console.log("Error setting up request:", error.message);
        if (error.code === "ERR_NETWORK") {
          notify("error", "Network error. Please try again later.");
        } else {
          notify("error", "An error occurred. Please try again.");
        }
      }
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <section className="loginpage">
      <div className="background">
        <div className="shape"></div>
        <div className="shape"></div>
      </div>
      <form className="loginform" onSubmit={handleLogin}>
        <div className="loginlogo">
          <Logo />
          <h5>Sign in to start your session</h5>
        </div>
        <div className="formfield">
          <input
            type="text"
            placeholder="Enter username"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <span>
            <IconLock />
          </span>
        </div>
        <div className="formfield">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Enter Password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span
            onClick={() => setShowPassword(!showPassword)}
            style={{ cursor: "pointer" }}
          >
            {showPassword ? <IconKey /> : <IconShowEye />}
          </span>
        </div>
        {/* <Link className="forgatepassword" to="#">forgot password</Link> */}
        <button type="submit" disabled={isLoading}>
          {isLoading ? (
              <>
              Signing in...              
            </>     
          ) : (
            <>
            Sign in <IconSignIn />
          </>            
          )}
        </button>
      </form>
    </section>
  );
}

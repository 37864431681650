import React from "react";
import PageLayout from "../../component/PageLayout";
import SliderHome from "../../component/Slider";
import OurService from "../../component/OurService";
import OurProcess from "../../component/OurProcess";
export default function Home({loading}) {
  return (
    <div className="homepage">
    <PageLayout>
      <SliderHome loading={loading}/>
      <OurProcess />      
      <OurService />
    </PageLayout>
    </div>
  );
}

import React from 'react'
import AdminHeader from '../../../../component/AdminComponent/AdminHeader'
import './style.css'

export default function Dashboard() {
  return (
    <section className='dashboardpage'>
      <AdminHeader pageTitle="Dashboard"/>
      <div className='pageLeftspace'>
      Dashboard
      </div>
    </section>
  )
}

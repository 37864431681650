import React from "react";

export default function PhoneIcon() {
  return (
    <svg
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m17.32 28.08c1.523.959 3.224 1.428 4.908 1.428 2.342 0 4.653-.908 6.41-2.665.59-.59.893-1.405.832-2.236-.061-.832-.48-1.594-1.15-2.091l-2.955-2.192c-1.132-.841-2.736-.723-3.733.275l-.423.423c-1.385 1.387-3.554 1.626-5.157.567-2.308-1.524-4.217-3.416-5.674-5.623-1.041-1.576-.791-3.725.594-5.109l.459-.459c.997-.997 1.115-2.602.275-3.734l-2.192-2.955c-.497-.67-1.259-1.089-2.09-1.15-.829-.062-1.647.242-2.237.832l-.009.009c-3.02 3.02-3.532 7.67-1.244 11.308 3.417 5.434 7.921 9.933 13.388 13.373zm-11.436-23.974.009-.009c.352-.352.826-.546 1.319-.546.046 0 .092.001.138.005.542.04 1.037.313 1.361.749l2.192 2.955c.546.737.47 1.782-.179 2.431l-.459.459c-1.719 1.719-2.022 4.396-.722 6.367 1.532 2.32 3.537 4.308 5.958 5.907 1.994 1.317 4.694 1.026 6.415-.695l.423-.423c.649-.648 1.693-.727 2.431-.179l2.955 2.192c.436.324.709.82.749 1.361.04.542-.158 1.072-.542 1.456-2.688 2.688-6.833 3.141-10.079 1.098-5.338-3.359-9.737-7.752-13.073-13.059-2.038-3.24-1.583-7.38 1.104-10.068z"        
      ></path>
    </svg>
  );
}

import React from "react";
import PageLayout from "../../component/PageLayout";
import "./style.css";

export default function AboutUs() {
  return (
    <PageLayout>
      <section className="section ptinner food-menu-main">
        <div className="titleone text-center">
          <h4>About Us – Spice on Wheels</h4>
        </div>
        <div className="container">
          <div className="aboutinfo">
            <div className="aboutinfocontent">
              {/* <h2><strong>Spice on Wheel</strong> – Bringing Flavor to Every Occasion!</h2> */}
              <div className="aboutbox aboutboxfirstbox">
               
                <div className="aboutinforight">
                  <h4>Welcome to</h4>
                  <h3>
                   <strong>Spice on Wheels</strong>
                  </h3>
                  <p>
                    where authentic Indian flavors meet the vibrant streets of
                    Stuttgart. Founded by <strong>Kalpesh Mhatre</strong> and{" "}
                    <strong>Shalvi Mehta</strong>, our journey is a blend of
                    passion, tradition, and the simple joy of sharing good food.
                  </p>
                </div>

                <div className="aboutinfoleft">
                  <img src="../images/logo.svg" alt="" />
                </div>
              </div>
              <div className="aboutbox">
                <h3>Meet the Founders </h3>
                <p>
                  <strong>Kalpesh Mhatre</strong>, originally from Mumbai,
                  India, is an IT professional with a thriving career in
                  Research and Development at Trelleborg Sealing Solutions.
                  While his professional life is rooted in technology, his heart
                  has always belonged to the culinary world. What started as
                  assisting his late wife, Prerana (meaning inspiration), in the
                  kitchen, soon blossomed into a deep passion for cooking. Her
                  memory continues to inspire Kalpesh every day, fueling his
                  dedication to both his professional and culinary pursuits. For
                  Kalpesh, cooking isn’t just about flavors—it’s about creating
                  happiness, one dish at a time.{" "}
                </p>
                <p>
                  <strong>Shalvi Mehta</strong> hails from Ahmedabad, Gujarat,
                  India, and has a rich background in Human Resources, having
                  worked with leading media, radio, television, and banking
                  industries in India. After moving to Stuttgart in 2019, she
                  yearned for the authentic Indian flavors she grew up with.
                  Inspired by her love for cooking and encouragement from
                  friends, Shalvi decided to turn her passion into a venture.
                  With unwavering support from her husband and a vision to bring
                  real Indian taste to Germany, <strong>Spice on Wheels</strong>{" "}
                  was born.{" "}
                </p>
              </div>
              <div className="aboutbox">
                <h3> Our Mission</h3>
                <p>
                  At <strong>Spice on Wheels</strong>, we serve{" "}
                  <strong>authentic vegetarian and vegan Indian cuisine</strong>
                  , crafted with love and rooted in rich traditions. We believe
                  food has the power to connect, nourish, and inspire. Our
                  mission goes beyond serving delicious meals—we aim to create a
                  community where people come together over flavors that tell
                  stories of home, culture, and memories.{" "}
                </p>
              </div>
              <div className="aboutbox">
                <h3>Why Choose Us? </h3>
                <ul>
                  <li>
                    🌱 <strong>100% Vegetarian & Vegan-Friendly:</strong> A
                    diverse menu that celebrates the vibrant flavors of Indian
                    cuisine.{" "}
                  </li>
                  <li>
                    🍛 <strong>Authentic Recipes:</strong> Inspired by
                    traditional cooking techniques and family recipes passed
                    down through generations.{" "}
                  </li>
                  <li>
                    🚚 <strong>Food on Wheels:</strong> Bringing the taste of
                    India directly to your Neighbourhood in Stuttgart.{" "}
                  </li>
                  <li>
                    ❤️ <strong>Passion in Every Bite:</strong> For us, food
                    isn’t just business—it’s a heartfelt experience.{" "}
                  </li>
                </ul>
                <p>
                  As the legendary cricketer <strong>Sachin Tendulkar</strong>{" "}
                  once said, <em>"Follow your dreams; dreams do come true."</em>{" "}
                  We are proud to be living our dream, and we invite you to be
                  part of our flavorful journey.
                </p>
                <p>
                  Come find us, taste the tradition, and let{" "}
                  <strong>Spice on Wheels</strong> take you on an unforgettable
                  culinary adventure.{" "}
                  <strong>
                    Because vegetarian food has never tasted this good!
                  </strong>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  );
}

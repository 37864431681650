import React from "react";

export default function LocationIcon() {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#000">
        <path d="m11.5 24c-.157 0-.304-.073-.399-.198-.33-.438-8.101-10.769-8.101-15.302 0-4.687 3.813-8.5 8.5-8.5s8.5 3.813 8.5 8.5c0 4.533-7.771 14.864-8.101 15.302-.095.125-.242.198-.399.198zm0-23c-4.136 0-7.5 3.364-7.5 7.5 0 3.648 5.895 11.963 7.5 14.159 1.605-2.197 7.5-10.513 7.5-14.159 0-4.136-3.364-7.5-7.5-7.5z"></path>
        <path d="m11.5 13c-2.481 0-4.5-2.019-4.5-4.5s2.019-4.5 4.5-4.5 4.5 2.019 4.5 4.5-2.019 4.5-4.5 4.5zm0-8c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5z"></path>
      </g>
    </svg>
  );
}

import React from "react";

export default function IconOrderLise() {
  return (
    <svg
      height="30"
      viewBox="0 0 64 64"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
      id="fi_1896981"
    >
      <path d="m59 14h-7v-3a1 1 0 0 0 -1-1h-5.62l-.241-1.326.81-.269a3 3 0 0 0 2.051-2.846v-.159a3.019 3.019 0 0 0 -2.217-2.9 14.45 14.45 0 0 0 -7.566 0 3.019 3.019 0 0 0 -2.217 2.9v.156a3 3 0 0 0 2.052 2.844l.809.269-.241 1.331h-5.62a1 1 0 0 0 -1 1v3h-7a3 3 0 0 0 -3 3v12h-11a1 1 0 0 0 -.6.2l-3.733 2.8h-3.667a1 1 0 0 0 -1 1v21a1 1 0 0 0 1 1h3.667l3.733 2.8a1 1 0 0 0 .6.2h11v1a3 3 0 0 0 3 3h34a3 3 0 0 0 3-3v-42a3 3 0 0 0 -3-3zm-9-2v6h-3.165l-1.091-6zm-5.2 6h-5.6l1.091-6h3.422zm-4.484-10.949-1.632-.544a1 1 0 0 1 -.684-.948v-.159a1 1 0 0 1 .749-.969 12.393 12.393 0 0 1 6.5 0 1 1 0 0 1 .751.969v.156a1 1 0 0 1 -.684.948l-1.632.544a1 1 0 0 0 -.668 1.128l.331 1.824h-2.694l.331-1.821a1 1 0 0 0 -.668-1.128zm-6.316 4.949h4.256l-1.091 6h-3.165zm-1 8h18a1 1 0 0 0 1-1v-3h2v40h-3v-2h-2v2h-2v-2h-2v2h-2v-2h-2v2h-2v-2h-2v2h-2v-2h-2v2h-3v-19.556a3.965 3.965 0 0 0 0-6.888v-13.556h2v3a1 1 0 0 0 1 1zm-9-3a1 1 0 0 1 1-1h3v13h-4zm-12.667 39-3.733-2.8a1 1 0 0 0 -.6-.2h-3v-19h3a1 1 0 0 0 .6-.2l3.733-2.8h16.667a2 2 0 0 1 0 4h-8a1 1 0 0 0 -.447.1l-3.789 1.9h-2.764v2h3a1 1 0 0 0 .447-.1l3.789-1.9h1.764v4h-4v2h4v3h-4v2h4v3h-4v2h4v3zm48.667 3a1 1 0 0 1 -1 1h-34a1 1 0 0 1 -1-1v-22h4v19a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2v-40h3a1 1 0 0 1 1 1z"></path>
      <path d="m34 30h10v2h-10z"></path>
      <path d="m46 30h4v2h-4z"></path>
      <path d="m34 34h10v2h-10z"></path>
      <path d="m46 34h4v2h-4z"></path>
      <path d="m34 38h10v2h-10z"></path>
      <path d="m46 38h4v2h-4z"></path>
      <path d="m34 42h10v2h-10z"></path>
      <path d="m46 42h4v2h-4z"></path>
      <path d="m38 48h6v2h-6z"></path>
      <path d="m46 48h4v2h-4z"></path>
      <path d="m40 24h10v2h-10z"></path>
    </svg>
  );
}

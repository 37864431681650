import React from "react";
import PageLayout from "../../component/PageLayout";
import LeafIcon from "../../component/Icons/LeafIcon";
import RoundCircalIcon from "../../component/Icons/RoundCircalIcon";
import "./style.css";

export default function FoodItems() {
  
  return (
    <PageLayout>  
      <section className="section food-menu-main ptinner">
      <div className="titleone text-center">
            <h4>Food Items Menu</h4>
          </div>
          
        <div className="container">        
          <div className="menu-main" style={{marginTop:20}}>
            <div className="menubar menubar02">
            <div className="menubox">
                <div className="maintitle">
                <h5>Chaats</h5> <span>Spicy, Sour, Salty & Sweet treats</span>
                </div>

                <div className="menucategory">
                  <div className="itemname">
                    <div className="categaryname">
                      <h6>Ragda Pattice</h6> <sup>H, 1</sup>
                    </div>{" "}
                    <figcaption>
                      <LeafIcon /> <RoundCircalIcon />
                      <span>€7.49</span>
                    </figcaption>
                  </div>
                  <p>
                  A Popular street Food from Mumbai, featuring Crispy Potato Patties
                  topped with a Spicy, Tangy Ragda (white peas curry), garnished with
                  chutneys, onions, and sev. 
                  </p>
                </div>
                <div className="menucategory">
                  <div className="itemname">
                    <div className="categaryname favrate">
                      <h6>Chhole Tikki Chat</h6> <sup>G, H, 1</sup>
                    </div>
                    <figcaption>
                      <LeafIcon /> <RoundCircalIcon /> <span>€7.49</span>
                    </figcaption>
                  </div>
                  <p>
                  Crispy golden aloo tikkis topped with spicy chole, tangytamarind chutney, yogurt, and a sprinkle of fresh onions and sev.
                  </p>
                </div>
              </div>

            

              <div className="menubox">
              <div className="maintitle">
                <h5>Mains</h5>
                <span>Vegetarian & Vegan Currys</span>
                </div>
                <div className="menucategory">
                  <div className="itemname">
                    <div className="categaryname favrate categaryname02">
                      <h6>Paneer Butter Masala</h6>{" "}
                      <label>
                        (Rice)<sup>E, G, 1</sup>
                      </label>
                    </div>{" "}
                    <figcaption>
                      <RoundCircalIcon /> <span>€8.49</span>
                    </figcaption>
                  </div>
                  <p>
                  Tender paneer in a creamy, buttery tomato gravy, served with
                  kulcha or rice for a truly irresistible treat
                  </p>
                </div>
                <div className="menucategory">
                  <div className="itemname">
                    <div className="categaryname favrate categaryname02">
                      <h6>Daal Makhani </h6>{" "}
                      <label>
                        (Rice) <sup>G, 1</sup>
                      </label>{" "}
                    </div>{" "}
                    <figcaption>
                      <RoundCircalIcon /> <span>€8.49</span>
                    </figcaption>
                  </div>
                  <p>
                  Rich and velvety blend of black lentils and kidney beans,
                  slow-cooked with aromatic spices and a touch of butter.
                  </p>
                </div>

                <div className="menucategory">
                  <div className="itemname">
                    <div className="categaryname categaryname02">
                      <h6>Chana Masala </h6>{" "}
                      <label>
                        (Rice)<sup>1</sup>
                      </label>{" "}
                    </div>{" "}
                    <figcaption>
                    <LeafIcon /> <RoundCircalIcon /> <span>€8.49</span>
                    </figcaption>
                  </div>
                  <p>
                  Tender paneer in a creamy, buttery tomato gravy, served with
                  kulcha or rice for a truly irresistible treat.
                  </p>
                </div>

                <div className="menucategory">
                  <div className="itemname">
                    <div className="categaryname">
                      <h6>Matar Kulcha </h6> <sup>A, G, N, 1</sup>
                    </div>{" "}
                    <figcaption>
                  <span>€7.99</span>
                    </figcaption>
                  </div>
                  <p>
                  A Delhi street food with flavorsome white peas garnished with
                  onions and chutneys served with soft, toasted kulcha, for a burst of
                  flavour in every bite.
                  </p>
                </div>
              </div>
            </div>

            <div className="menubar">
            <div className="menubox">
            <div className="maintitle">
                <h5>Bowls</h5>
                <span>Healthy and Wellness Bow</span>
                </div>
                <div className="menucategory">
                  <div className="itemname">
                    <div className="categaryname">
                      <h6>Paneer Bowl</h6> <sup>G, H, 1</sup>
                    </div>{" "}
                    <figcaption>
                      <LeafIcon /> <span>€7.49</span>
                    </figcaption>
                  </div>
                  <p>
                  A Paneer bowl is wholesome dish featuring soft cubes of paneer
                  paired with vegetables, herbed rice and flavourful Sauces.
                  </p>
                </div>
                <div className="menucategory">
                  <div className="itemname">
                    <div className="categaryname favrate">
                      <h6>Veggie Protein Bowl</h6> <sup>H, 1</sup>
                    </div>{" "}
                    <figcaption>
                    <LeafIcon /> <RoundCircalIcon /> <span>€7.49</span>
                    </figcaption>
                  </div>
                  <p>
                  Combination of Vegan protein, herbed rice, vegetables, and
                  toppings, designed to fuel and satisfy.
                  </p>
                </div>
              </div>

              <div className="menubox">
              <div className="maintitle">
                <h5>Beverages</h5>                
                  </div>
                <div className="menucategory">
                  <div className="itemname">
                    <div className="categaryname">
                      <h6>Mango Lassi</h6> <sup>G, 1</sup>
                    </div>{" "}
                    <figcaption>
                      <RoundCircalIcon /> <span>€2.49</span>
                    </figcaption>
                  </div>
                  <p>
                  A silky, indulgent drink made with smooth mango flavored yogurt
                  blended to perfection for a cooling, tropical treat.
                  </p>
                </div>
                <div className="menucategory">
                  <div className="itemname">
                    <div className="categaryname">
                      <h6>Indian Masala Chai</h6> <sup>G, 1</sup>
                    </div>{" "}
                    <figcaption>
                      <RoundCircalIcon /> <span>€1.49</span>
                    </figcaption>
                  </div>
                  <p>
                  A fragrant and spiced tea brewed in milk and a perfect blend of
                  fresh ginger and aromatic spices.
                  </p>
                </div>
              </div>

              <div className="menubox">
              <div className="maintitle">
                <h5>Extras</h5>
                </div>
                <div className="menucategory">
                  <div className="itemname">
                    <div className="categaryname">
                      <h6>Chapati / Roti</h6> <sup>A, 1</sup>
                    </div>{" "}
                    <figcaption>
                      <LeafIcon /> <span>€1</span>
                    </figcaption>
                  </div>
                  <div className="itemname">
                    <div className="categaryname">
                      <h6>Papad / Papadam</h6> <sup>G, 1</sup>
                    </div>{" "}
                    <figcaption>
                      <LeafIcon /> <span>€1</span>
                    </figcaption>
                  </div>
                  <div className="itemname">
                    <div className="categaryname">
                      <h6>Plain Paratha</h6> <sup>A, 1</sup>
                    </div>{" "}
                    <figcaption>
                      <LeafIcon /> <span>€1</span>
                    </figcaption>
                  </div>
                  <div className="itemname">
                    <div className="categaryname">
                      <h6>Kulcha</h6> <sup>A, G, N, 1</sup>
                    </div>
                    <figcaption>
                      <span>€1,49</span>
                    </figcaption>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>        
      </section>
    </PageLayout>
    
  );
}

import React from "react";

export default function LeafIcon() {
  return (
    <svg
      width="80"
      height="155"
      viewBox="0 0 147 155"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M125.269 25.5C63.569 77.1 59.2691 152.2 59.2691 152.2C134.369 108.4 146.169 0 146.169 0C53.7691 53.4 46.8691 98.3 52.7691 154.1C64.1691 59.3 124.369 26.1 125.269 25.5Z"
        fill="#CBCC2C"
      />
      <path
        d="M47.8688 145.3C47.3688 139.6 46.8688 133.7 46.6688 127.9C39.1688 103.4 28.6689 74.8001 15.5689 45.6001C15.9689 46.2001 38.2688 82.8001 46.8688 113.2C47.3688 105.3 48.5688 97.3001 50.8688 89.3001C43.5688 63.4001 21.6689 29.4001 0.168852 0.100098C0.168852 0.100098 -5.43115 103.7 47.8688 145.3Z"
        fill="#CBCC2C"
      />
    </svg>
  );
}

import { cn } from "../../lib/utils";

// BentoGrid Component
export const BentoGrid = ({ className, children }) => {
  return (
    <div
      className={cn(
        "grid md:auto-rows-[18rem] grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 max-w-7xl mx-auto ",
        className
      )}
    >
      {children}
    </div>
  );
};

// BentoGridItem Component
export const BentoGridItem = ({
  className,
  title,
  img,
  imageLoading,
  handleImageLoad,
  index,
}) => {
  return (
    <div
      className={cn(
        "row-span-1 rounded-xl group/bento hover:shadow-xl transition duration-200 shadow-input p-4 border-white/[0.2] bg-[#262626] border justify-between flex flex-col space-y-4",
        className
      )}
    >
      <div className="w-auto overflow-hidden">
        {/* Image rendering with lazy loading */}
        <div
          className={`gallerypicfront relative w-full object-cover rounded-xl ${
            imageLoading[index] ? "bg-gray-300" : ""
          }`}         
        >
          <img
            src={img}
            alt={title}
            className="rounded-xl"
            loading="lazy"
            onLoad={() => handleImageLoad(index)} 
          />
        </div>
      </div>
      <div className="group-hover/bento:translate-x-2 transition duration-200">
        <div className="font-bold text-white">{title}</div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import AdminHeader from "../../../../component/AdminComponent/AdminHeader";
import api from "../../../../services/api";
import PaginationWidget from "../../../../component/AdminComponent/PaginationWidget";

const ENTRIES_PER_PAGE = 20;

export default function ContactList() {
  const [formattedData, setFormattedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const response = await api.post("/list_contact_us", {}, {
          headers: { "Content-Type": "application/json" },
        });
        if (isMounted) {
          setFormattedData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  // Calculate total pages and displayed data based on current page
  const totalPages = Math.ceil(formattedData.length / ENTRIES_PER_PAGE);
  const displayedData = formattedData.slice(
    (currentPage - 1) * ENTRIES_PER_PAGE,
    currentPage * ENTRIES_PER_PAGE
  );

  return (
    <section className="locationpage">
      <AdminHeader pageTitle="List of Contacts" />
      <div className="pageLeftspace">
        <div className="tableheader flex-column gap-4 d-flex justify-content-between align-items-center">
          <table className="table table-bordered">
            <thead>
              <tr>
                {displayedData.length > 0 &&
                  Object.keys(displayedData[0]).map((key, index) => (
                    <th key={index}>{key}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {displayedData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.values(row).map((value, colIndex) => (
                    <td key={colIndex}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {totalPages > 1 && (
          <PaginationWidget totalPages={totalPages} onPageChanged={setCurrentPage} />
        )}
        </div>
      
      </div>
    </section>
  );
}

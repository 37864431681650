import React, { useEffect, useState } from "react";
import AdminHeader from "../../../../component/AdminComponent/AdminHeader";
import IconClose from "../../../../component/Icons/IconClose";
import api from "../../../../services/api";
import "./style.css";
import { useToast } from "../../../../services/Toster";
import IconArrowLeft from "../../../../component/Icons/IconArrowLeft";
import PaginationWidget from "../../../../component/AdminComponent/PaginationWidget";

export default function AddDishes() {
  const initialFormData = {
    Dish_Name: "",
    Category: "",
    Description: "",
    Price: "",
  };
  const notify = useToast();
  const [menuItems, setMenuItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  // Adjust items per page based on window width
  const handleResize = () => {
    if (window.innerWidth <= 1450) {
      setItemsPerPage(10);
    } else {
      setItemsPerPage(20);
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await api.post("/list_categories");
      setCategories(response.data.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  useEffect(() => {
    fetchCategories();
  }, []);

  // dish list data api
  const fetchData = async () => {
    try {
      const response = await api.post(
        "/listdishes",
        {},
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const reversedItems = response.data.data.reverse();
      setMenuItems(reversedItems);
      setTotalPages(Math.ceil(reversedItems.length / itemsPerPage));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [itemsPerPage]);

  const paginateItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return menuItems.slice(startIndex, endIndex);
  };

  // input data get handleInputChange
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // dish name add api
  const handleAddItem = async () => {
    // Validate required fields
    if (!formData.Category || !formData.Dish_Name) {
      notify("error", "Category and Dish Name are required.");
      return;
    }
    const isDuplicate = menuItems.some(
      (item) =>
        item.Category === formData.Category &&
        item.Dish_Name === formData.Dish_Name
    );

    if (isDuplicate) {
      notify(
        "error",
        "Dish with the same name already exists in this category."
      );
      return;
    }

    setIsLoading(true);
    try {
      const _newDish = {
        ...formData,
        Date_Time_Stamp: new Date(),
      };
      const response = await api.post("/dishes", _newDish, {
        headers: { "Content-Type": "application/json" },
      });
      console.log("response.status", response);
      if (response.status === 201 && response.data) {
        fetchData();
        setFormData(initialFormData);
        notify("success", "Dish added successfully");
      } else {
        // console.error("Error: API did not return expected data.", response.data);
        notify("error", "Failed to save dish. Please try again.");
      }
    } catch (error) {
      //console.error("Error fetching data:", error);
      notify("error", "Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Remove Dish card
  const handleRemoveItem = async (index, categoryId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this dish?"
    );
    if (!confirmDelete) return; // Exit if user cancels
    try {
      const response = await api.post(
        "/delete_dish",
        { id: categoryId },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.status === 200) {
        setMenuItems((prevItems) => prevItems.filter((_, i) => i !== index));
        notify("success", "Dish delete successfully");
      } else {
        //console.error("Failed to delete dish");
        notify("error", "Failed to delete dish");
      }
    } catch (error) {
      //console.error("Error deleting dish:", error);
      notify("error", "Error deleting dish:", error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <section className="dashboardpage">
      <AdminHeader pageTitle="Add Catering Dish" />
      <div className="pageLeftspace">
        <div className="form-fields">
          <form>
            <div className="grid-container">
              <div className="grid-item custom-select">
                {/* <input
                  type="text"
                  className="form-control"
                  placeholder="Category Name"
                  name="Category"
                  value={formData.Category}
                  onChange={handleInputChange}
                /> */}
                <label>Select Category <sup>*</sup></label>
                <IconArrowLeft />
                <select
                  value={formData.Category}
                  name="Category"
                  className="form-control"
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Category Name</option>
                  {categories.map((cat) => (
                    <option key={cat.id} value={cat.Category}>
                      {cat.Category}
                    </option>
                  ))}
                </select>
              </div>
              <div className="grid-item">
                <label>Dish Name <sup>*</sup></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Dish Name"
                  name="Dish_Name"
                  value={formData.Dish_Name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="grid-item">
                <label>Dish Quantity</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Quantity"
                  name="Description"
                  value={formData.Description}
                  onChange={handleInputChange}
                />
              </div>
              <div className="grid-item">
                <label>Dish Price</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Dish Price"
                  name="Price"
                  value={formData.Price}
                  onChange={handleInputChange}
                />
              </div>

              {/*
            <textarea
                name="description"
                placeholder="Enter Description"
                 className="form-control"
                 id="description"
                rows={2}/>

              </div> */}
              <button
                className="btn"
                type="button"
                onClick={handleAddItem}
                disabled={isLoading}
              >
                {isLoading ? "Save Item..." : "Save Item"}
              </button>
            </div>
          </form>

          <div className="menuItems">
            {paginateItems().length > 0 ? (
              paginateItems().map((item, index) => (
                <div className="dishname" key={item.ID}>
                  <IconClose onClick={() => handleRemoveItem(index, item.ID)} />
                  <h4>{item.Category}</h4>
                  <div className="description">
                    <span>
                      <label htmlFor={item.dish}>
                        {item.Dish_Name && ` ${item.Dish_Name}`}
                      </label>
                    </span>
                    {item.Description && (
                      <div
                        className="textariabox"
                        dangerouslySetInnerHTML={{
                          __html: "Total Qty: " + item.Description,
                        }}
                      />
                    )}
                    {item.Price && (
                      <div
                        className="textariabox"
                        dangerouslySetInnerHTML={{
                          __html: "Dish Price: " + item.Price,
                        }}
                      />
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p>No Dish Available</p>
            )}
          </div>

          {menuItems.length > itemsPerPage && (
            <PaginationWidget
              totalPages={totalPages}
              onPageChanged={handlePageChange}
            />
          )}
        </div>
      </div>
    </section>
  );
}

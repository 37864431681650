import React from "react";
import "./style.css";
import OurSpecialDish from "../OurSpecialDish";

export default function OurProcess() {
  return (
    <section className="ourdishes">
      <div className="w-full">
        <div className="section heightinscreen todaylocationsection">
        <div className="titleone text-center">
            <h4>Meet Our Mains</h4>
            {/* <h2>All day food soluation</h2> */}
            {/* <h3>Meet Our Mains</h3> */}
          </div>
        <div className="bigscreen">
        <div className="container">                 
        <div className="ourdishescurve">
          <ol className="disc-list">
            <li>
              <div className="cardpic">
                <img src="../images/DaalMakhani.webp" alt="Daal Makhani" />
                <span>Daal Makhani</span>
              </div>
              <label />
            </li>
            <li className="bottoside">
              <div className="cardpic">
                <img src="../images/PaneerButterMasala.webp" alt="Paneer Butter Masala" />
                <span>Paneer Butter Masala</span>
              </div>
              <label />
            </li>
            <li>
              <div className="cardpic">
                <img src="../images/ChanaMasala.webp" alt="Chana Masala" />
                <span>Chana Masala</span>
              </div>
              <label />
            </li>
            <li className="bottoside">
              <div className="cardpic">
                <img src="../images/ChholeTikkiChat.webp" alt="Chhole Tikki Chat" />
                <span>Chhole Tikki Chat</span>
              </div>
              <label />
            </li>
          </ol>
        </div>
        </div>
        </div>
        </div>
        <OurSpecialDish />
      </div>
    </section>
  );
}

import React from "react";

export default function IconUser() {
  return (
    <svg
      id="Layer_1"
      enableBackground="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="m443.228 425.001c36.035-42.658 57.77-97.786 57.77-158 0-135.309-109.69-244.999-244.999-244.999s-244.998 109.69-244.998 244.999c0 60.491 21.935 115.85 58.268 158.588z"
          fill="#ff4155"
        />
        <path
          d="m341.178 379.922c-31.892 53.969-83.313 54.442-83.313 54.442s-51.377-.473-83.27-54.379c-.731.886-1.484 1.747-2.287 2.55-4.279.987-8.494 2.076-12.665 3.241 2.294.999 4.29 2.698 5.623 4.975 19.611 33.494 53.803 73.501 92.709 73.501s73.098-40.007 92.709-73.501c1.324-2.26 3.301-3.951 5.574-4.954-4.196-1.174-8.436-2.269-12.741-3.262-.822-.821-1.592-1.704-2.339-2.613z"
          fill="#fcad6d"
        />
        <path
          d="m228.866 427.659c-16.947-6.766-38.11-20.357-54.272-47.673-.731.886-1.484 1.748-2.287 2.55-4.279.988-8.494 2.076-12.665 3.242 2.294.999 4.29 2.698 5.623 4.975 11.363 19.408 27.623 41.001 46.859 55.732.998-.382 1.851-.751 2.524-1.112 7.456-3.998 11.927-12.192 14.218-17.714z"
          fill="#fd995b"
        />
        <path
          d="m388.813 102.363c0-27.331-15.746-38.656-26.161-43.157-5.249-2.269-9.348-6.407-11.433-11.573-4.557-11.293-17.443-30.861-53.475-42.819-52.975-17.582-100.064 18.148-125.375 24.954-16.645 4.475-29.725-5.397-36.732-12.682-2.961-3.079-8.225-2.147-9.906 1.725-5.471 12.602-13.442 37.386-6.149 63.16.447 1.579-1.153 2.987-2.754 2.438-2.116-.725-4.017-1.401-5.544-1.955-1.564-.566-3.124.847-2.627 2.383 6.164 19.052 12.139 29.92 17.501 35.782 2.373 2.594 3.959 5.777 4.369 9.212.797 6.691 2.135 13.823 4.34 20.43 3.255 9.755 5.296 19.863 5.884 30.13.007.12.012.19.016.204h236.924c.011-.049.024-.22.039-.493.575-10.068 2.539-20.012 5.768-29.565 7.196-21.284 5.315-48.174 5.315-48.174z"
          fill="#41416b"
        />
        <path
          d="m205.188 114.632s22.011-26.365 6.676-56.119 21.744-53.247 21.744-53.247c-25.491 7.462-46.874 20.64-61.239 24.502-16.644 4.476-29.725-5.397-36.732-12.682-2.961-3.079-8.225-2.147-9.906 1.725-5.471 12.602-13.442 37.386-6.149 63.16.447 1.579-1.153 2.987-2.754 2.438-2.116-.725-4.017-1.401-5.544-1.955-1.564-.566-3.124.847-2.627 2.383 6.164 19.052 12.139 29.921 17.501 35.782 2.373 2.594 3.959 5.777 4.369 9.212.797 6.691 2.135 13.822 4.34 20.43 3.255 9.755 5.296 19.863 5.884 30.13.007.12.012.19.016.204h49.963z"
          fill="#35355e"
        />
        <path
          d="m385.261 179.539c-5.313-4.869-15.897-2.401-20.473.581 1.003 6.243 1.059 12.629.194 18.936l-5.4 39.329c0 3.17-.1 6.281-.284 9.337 13.168 3.972 19.708-7.85 20.81-15.962.474-3.492 1.25-6.934 2.325-10.293 3.504-10.939 16.14-29.729 2.828-41.928z"
          fill="#fcad6d"
        />
        <g>
          <path
            d="m375.261 216.751c-4.887 0-9.204-2.395-11.876-6.064l-3.803 27.698c0 3.17-.1 6.281-.284 9.337 13.168 3.972 19.708-7.849 20.81-15.962.474-3.492 1.25-6.934 2.326-10.293.906-2.83 2.424-6.189 3.946-9.82-2.695 3.121-6.672 5.104-11.119 5.104z"
            fill="#fd995b"
          />
        </g>
        <path d="m150.05 180.872v.001z" fill="#41416b" />
        <path
          d="m130.474 179.539c5.313-4.869 15.897-2.401 20.473.581-1.003 6.243-1.06 12.629-.193 18.936l5.4 39.329c0 3.17.1 6.281.284 9.337-13.167 3.972-19.708-7.85-20.81-15.962-.474-3.492-1.25-6.934-2.325-10.293-3.505-10.939-16.141-29.729-2.829-41.928z"
          fill="#fcad6d"
        />
        <path
          d="m357.996 159.694c-3.27-5.13-4.708-11.198-4.27-17.249.265-3.664.441-8.069.346-12.83-.038-.626-.058-1.305-.058-2.045-.05-1.387-.127-2.799-.231-4.227-1.282-12.745-8.132-21.924-13.587-27.32-3.643-3.604-9.26-4.312-13.747-1.838-67.438 37.186-123.327 23.448-153.418 9.904-3.646-1.641-7.852.486-8.81 4.368-3.002 12.166-2.849 25.322-2.201 34.137.441 6.004-1.037 12.01-4.282 17.101-1.87 2.934-3.366 6.04-4.563 9.114-3.882 9.971-5.013 20.786-3.559 31.375l5.401 39.338c0 .071.003.139.003.21 5.553 16.196 16.789 39.414 36.865 46.004l.254.073c12.082 3.472 21.423 13.002 25.384 25.156 5.594 17.167 21.47 21.731 40.341 21.731 18.88 0 34.745-4.567 40.339-21.725 3.963-12.155 13.302-21.689 25.386-25.162l.254-.073c20.078-6.591 31.317-29.814 36.871-46.01 0-.069.003-.136.003-.204l5.4-39.329c1.44-10.485.348-21.194-3.444-31.087-1.214-3.173-2.746-6.384-4.677-9.412z"
          fill="#f9ba8f"
        />
        <g>
          <path
            d="m140.474 216.751c4.887 0 9.204-2.395 11.876-6.064l3.803 27.698c0 3.17.1 6.281.283 9.337-13.168 3.972-19.708-7.849-20.81-15.962-.474-3.492-1.25-6.934-2.326-10.293-.906-2.83-2.424-6.189-3.946-9.82 2.696 3.121 6.673 5.104 11.12 5.104z"
            fill="#fd995b"
          />
        </g>
        <path
          d="m194.605 200.183c-1.715-15.753 1.357-30.431 6.439-40.488 2.573-5.091 3.745-11.097 3.395-17.101-.427-7.337-.581-17.683.749-27.962-12.616-2.707-23.429-6.619-32.104-10.519-3.67-1.65-7.898.436-8.862 4.342-3.003 12.167-2.849 25.324-2.202 34.139.441 6.004-1.037 12.009-4.282 17.101-6.497 10.193-10.263 24.895-8.122 40.488l5.402 39.338c0 .071.003.14.004.21 5.553 16.196 16.789 39.413 36.865 46.004l.254.073c5.751 1.653 10.87 4.69 15.094 8.717-5.103-15.877-8.346-34.211-8.346-55.004z"
          fill="#fcad6d"
        />
        <path
          d="m294.714 198.959c0-1.851.346-3.612.965-5.213-2.406 1.181-4.349 2.518-5.732 3.647-3.228 2.632-8.839 5.971-11.912 7.736-.581.334-.344 1.221.326 1.221h.303c2.851 0 5.687.445 8.395 1.337 4.697 1.547 9.191 2.316 13.347 2.57-3.415-2.342-5.692-6.524-5.692-11.298z"
          fill="#fff5ee"
        />
        <path
          d="m332.932 201.855c-5.612-5.264-11.006-8.302-15.995-9.84 1.122 2.021 1.771 4.398 1.771 6.944 0 4.161-1.731 7.872-4.436 10.307 4.084-.844 7.272-2.018 9.246-2.917 4.049-1.844 7.112-2.594 8.933-2.899.758-.126 1.041-1.069.481-1.595z"
          fill="#fff5ee"
        />
        <path
          d="m294.925 194.129c-.684 1.563-1.066 3.288-1.066 5.104 0 4.698 2.544 8.799 6.327 11.01 5.457.356 10.338-.181 14.337-1.028 2.939-2.335 4.825-5.937 4.825-9.982 0-2.65-.809-5.111-2.194-7.149-9.139-2.892-16.933-.735-22.229 2.045z"
          fill="#41416b"
        />
        <path
          d="m311.357 200.033c0 2.698-2.187 4.885-4.885 4.885s-4.885-2.187-4.885-4.885 2.187-4.885 4.885-4.885 4.885 2.187 4.885 4.885z"
          fill="#35355e"
        />
        <path
          d="m343.727 183.763c-.73-4.799-3.638-15.953-14.985-18.532-11.458-2.604-35.619 2.304-45.401 4.516-2.606.589-4.671 2.571-5.37 5.15l-2.015 7.432c-.171.631.41 1.207 1.039 1.031 7.416-2.081 41.565-11.315 51.967-7.978 7.933 2.544 12.007 6.51 13.808 8.798.342.434 1.041.13.957-.417z"
          fill="#41416b"
        />
        <path
          d="m223.623 198.959c0-1.851-.346-3.612-.965-5.213 2.406 1.181 4.349 2.518 5.733 3.647 3.228 2.632 8.839 5.971 11.911 7.736.581.334.344 1.221-.326 1.221h-.303c-2.851 0-5.687.445-8.395 1.337-4.697 1.547-9.191 2.316-13.347 2.57 3.415-2.342 5.692-6.524 5.692-11.298z"
          fill="#fff5ee"
        />
        <path
          d="m185.405 201.855c5.612-5.264 11.006-8.302 15.995-9.84-1.122 2.021-1.771 4.398-1.771 6.944 0 4.161 1.731 7.872 4.436 10.307-4.084-.844-7.272-2.018-9.246-2.917-4.05-1.844-7.112-2.594-8.933-2.899-.758-.126-1.041-1.069-.481-1.595z"
          fill="#fff5ee"
        />
        <path
          d="m223.412 194.129c.684 1.563 1.066 3.288 1.066 5.104 0 4.698-2.544 8.799-6.327 11.01-5.457.356-10.338-.181-14.337-1.028-2.939-2.335-4.825-5.937-4.825-9.982 0-2.65.809-5.111 2.194-7.149 9.139-2.892 16.933-.735 22.229 2.045z"
          fill="#41416b"
        />
        <path
          d="m206.98 200.033c0 2.698 2.187 4.885 4.885 4.885s4.885-2.187 4.885-4.885-2.187-4.885-4.885-4.885-4.885 2.187-4.885 4.885z"
          fill="#35355e"
        />
        <path
          d="m174.609 183.763c.73-4.799 3.638-15.953 14.985-18.532 11.458-2.604 35.619 2.304 45.401 4.516 2.606.589 4.671 2.571 5.37 5.15l2.015 7.432c.171.631-.41 1.207-1.04 1.031-7.416-2.081-41.564-11.315-51.967-7.978-7.933 2.544-12.007 6.51-13.808 8.798-.34.434-1.039.13-.956-.417z"
          fill="#41416b"
        />
        <path
          d="m287.792 297.78-10.908 4.301c-1.227.484-2.582.539-3.844.159l-12.571-3.791-12.57 3.791c-1.263.38-2.618.325-3.845-.159l-10.908-4.301c-3.013-1.188-4.493-4.595-3.305-7.608 1.185-3.005 4.585-4.496 7.608-3.305l9.013 3.554 12.259-3.697c1.188-.371 2.426-.344 3.497.001l12.257 3.696 9.013-3.554c3.008-1.184 6.416.284 7.608 3.305 1.19 3.014-.29 6.42-3.304 7.608z"
          fill="#fcad6d"
        />
        <g>
          <path
            d="m302.393 166.118c-7.6 1.149-14.73 2.652-19.052 3.629-2.606.589-4.671 2.571-5.37 5.15l-2.015 7.432c-.171.631.41 1.207 1.04 1.031 4.112-1.154 16.441-4.504 28.385-6.694-4.913-.949-6.712-7.118-2.988-10.548z"
            fill="#35355e"
          />
        </g>
        <g>
          <path
            d="m208.999 165.2c-7.376-.823-14.537-1.074-19.404.032-11.348 2.579-14.255 13.732-14.985 18.532-.083.546.615.851.957.417 1.801-2.288 5.875-6.254 13.808-8.798 3.959-1.27 11.359-.717 19.414.567-4.191-2.432-4.056-8.497.21-10.75z"
            fill="#35355e"
          />
        </g>
        <path
          d="m362.951 223.253s-9.442 50.939-40.523 61.142l-.248.071c-11.825 3.398-20.963 12.727-24.841 24.622-5.474 16.791-20.999 21.259-39.474 21.259-18.467 0-34.001-4.466-39.476-21.265-3.876-11.893-13.017-21.219-24.839-24.616l-.248-.071c-31.081-10.203-40.518-61.142-40.518-61.142-23.891 213.013 105.081 214.189 105.081 214.189s128.978-1.176 105.086-214.189z"
          fill="#41416b"
        />
        <path
          d="m264.234 311.872h-7.529c-3.24 0-5.865 2.626-5.865 5.865 0 3.24 2.626 5.865 5.865 5.865h7.529c3.24 0 5.865-2.626 5.865-5.865s-2.626-5.865-5.865-5.865z"
          fill="#fcad6d"
        />
        <path
          d="m193.302 286.436.248.071c8.031 2.308 14.818 7.356 19.564 14.081l5.482-3.642c3.149-2.092 6.861-3.229 10.64-3.108 13.136.421 24.644-3.407 29.179-5.16 1.324-.512 2.786-.512 4.11 0 4.535 1.753 16.043 5.581 29.179 5.16 3.779-.121 7.491 1.016 10.64 3.108l2.035 1.352c4.603-5.574 10.714-9.753 17.802-11.79l.248-.071c2.925-.96 5.659-2.282 8.211-3.888-8.928-15.274-29.387-36.734-70.17-21.942-42.779-15.516-63.198 8.856-71.4 24.135 1.363.647 2.774 1.215 4.232 1.694z"
          fill="#41416b"
        />
        <path
          d="m240.792 257.403 4.472-.942c8.308 8.207 22.115 8.193 30.41 0l4.472.942c3.214.673 6.377-1.382 7.055-4.602.678-3.219-1.382-6.377-4.601-7.055l-8.023-1.689c-2.613-.55-5.162.499-6.395 2.863-3.245 5.018-12.175 5.025-15.425 0-1.233-2.364-3.783-3.411-6.395-2.863l-8.024 1.689c-3.219.678-5.279 3.837-4.601 7.055.677 3.219 3.837 5.277 7.055 4.602z"
          fill="#fcad6d"
        />
        <path
          d="m256 512c75.567 0 143.134-34.221 188.075-88.001-6.081-1.428-10.757-4.559-19.103-9.265-20.572-11.6-41.422-22.391-63.639-29.435-5.212-1.652-10.899.543-13.661 5.261-18.987 32.428-52.092 71.163-89.76 71.163s-70.773-38.734-89.76-71.163c-2.763-4.718-8.449-6.914-13.661-5.261-22.217 7.043-43.067 17.835-63.638 29.435-9.797 5.524-14.535 8.881-22.427 9.857 44.941 53.438 112.285 87.409 187.574 87.409z"
          fill="#fff5ee"
        />
        <path
          d="m168.152 390.561c-2.763-4.718-8.449-6.913-13.661-5.261-22.217 7.044-43.067 17.835-63.638 29.435-9.797 5.524-14.535 8.881-22.427 9.857 19.774 23.512 43.888 43.25 71.104 57.989 22.497-25.636 65.696-32.157 75.118-37.208.001 0 .001-.001.002-.001-19.116-14.274-35.275-35.642-46.498-54.811z"
          fill="#fce6d6"
        />
        <g fill="#35355e">
          <path d="m226.861 256.214c-20.643 3.1-32.129 17.978-37.791 28.526 1.364.648 2.774 1.216 4.232 1.695l.248.071c8.031 2.308 14.818 7.356 19.565 14.081l5.482-3.642c3.149-2.092 6.861-3.229 10.64-3.108 2.712.087 5.348-.014 7.875-.234-23.08-13.877-10.256-37.381-10.251-37.389z" />
          <path d="m253.19 378.561c-20.43-25.517 7.279-48.213 7.279-48.213l-.015-.034c-.857.021-1.719.034-2.59.034-18.467 0-34.002-4.466-39.476-21.265-3.876-11.893-13.016-21.219-24.839-24.617l-.248-.071c-31.081-10.203-40.518-61.142-40.518-61.142-23.891 213.013 105.081 214.189 105.081 214.189s23.028-24.281-4.674-58.881z" />
        </g>
      </g>
    </svg>
  );
}

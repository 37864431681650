import React, { createContext, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastContext = createContext();

export const useToast = () => {
  return useContext(ToastContext);
};

export const ToastProvider = ({ children }) => {
  const notify = (type, message) => {
    switch (type) {
      case 'success':
        toast.success(message);
        break;
      case 'error':
        toast.error(message);
        break;
      case 'info':
        toast.info(message);
        break;
      case 'warn':
        toast.warn(message);
        break;
      default:
        toast(message);
        break;
    }
  };

  return (
    <ToastContext.Provider value={notify}>
      {children}
      <ToastContainer
        position="top-center"
        autoClose={5000}          // Adjust the duration
        hideProgressBar={false}  // Show the progress bar
        progressBar={true}       // Enable the progress bar to appear
        newestOnTop={false}      // Toasts appear in order
        //closeButton={false}      // Disable close button
        pauseOnFocusLoss
        draggable
        pauseOnHover
         style={{          
        //   position: 'fixed',
        //   top: '50%',   // Vertically center the toast
        //   left: '50%',  // Horizontally center the toast
        //   transform: 'translate(-50%, -50%)', // Adjust the position to be perfectly centered
        //   zIndex: 9999,  // Ensure the toast appears above other elements
        //   animation: 'zoomIn 0.5s ease-out'
         }}
      />
    </ToastContext.Provider>
  );
};

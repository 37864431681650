import React, { useEffect, useState } from "react";
import { BentoGrid, BentoGridItem } from "./BentoGrid";
import PageLayout from "../../component/PageLayout";
import api from "../../services/api";

export function Gallery() {
  const [galleryPic, setGalleryPic] = useState([]);
  const [imageLoading, setImageLoading] = useState({});
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await api.post("/List_Image_Gallery", {}, {
          headers: { "Content-Type": "application/json" },
        });

        if (response.data && response.data.success) {
          setGalleryPic(response.data.data);
          setImageLoading(
            response.data.data.reduce((acc, _, index) => ({ ...acc, [index]: true }), {})
          );
        }
      } catch (error) {
        console.error("Error fetching gallery images:", error);
      } finally {
        setLoading(false); 
      }
    };

    fetchImages();
  }, []);

  const handleImageLoad = (index) => {
    setImageLoading((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };

  return (
    <PageLayout>
      <section className="section gallerypagemain ptinner">
      <div className="titleone text-center">
            <h4>Our Gallery</h4>
          </div>
        <div className="container">
          {loading ? (
            <div className="bookform-left text-center py-32">Loading Gallery Pictures...</div>
          ) : galleryPic.length === 0 ? (
            <p className="bookform-left text-center py-32">No Gallery Pictures available</p>
          ) : (
            <BentoGrid className="mx-auto">
              {galleryPic.map((item, i) => (
                <BentoGridItem
                  key={i}
                  title={item.Image_Title}
                  img={`${process.env.REACT_APP_API_PRJ_URL}${item.Image_Path}`}
                  className={i === 3 || i === 6 ? "md:col-span-1 lg:col-span-2" : ""}
                  imageLoading={imageLoading[i]} 
                  handleImageLoad={() => handleImageLoad(i)}
                  index={i}
                />
              ))}
            </BentoGrid>
          )}
        </div>
      </section>
    </PageLayout>
  );
}

import React from 'react'

export default function RoundCircalIcon() {
  return (
    <svg width="149" height="151" viewBox="0 0 149 151" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M100.969 34.4C97.369 24.1 87.769 16.9 82.569 23.8C79.169 31.7 90.169 36.4 100.969 34.4Z" fill="#CBCC2C"/>
<path d="M100.969 34.4C97.369 24.1 87.769 16.9 82.569 23.8C79.169 31.7 90.169 36.4 100.969 34.4Z" stroke="#231F20" strokeWidth="0.1" strokeMiterlimit="10"/>
<path d="M64.3689 24.6998C61.6689 32.8998 73.0689 36.6998 83.5689 33.6998C79.0689 23.5998 68.8689 17.2998 64.3689 24.6998Z" fill="#CBCC2C"/>
<path d="M64.3689 24.6998C61.6689 32.8998 73.0689 36.6998 83.5689 33.6998C79.0689 23.5998 68.8689 17.2998 64.3689 24.6998Z" stroke="#231F20" strokeWidth="0.1" strokeMiterlimit="10"/>
<path d="M94.0693 2.5C83.1693 3.6 73.9693 11.2 79.4693 17.9C86.3693 23.1 93.5693 13.5 94.0693 2.5Z" fill="#CBCC2C"/>
<path d="M94.0693 2.5C83.1693 3.6 73.9693 11.2 79.4693 17.9C86.3693 23.1 93.5693 13.5 94.0693 2.5Z" stroke="#231F20" strokeWidth="0.1" strokeMiterlimit="10"/>
<path d="M103.669 32.3002C110.069 35.5002 115.769 39.9002 120.369 45.3002L133.169 37.9002C128.369 31.9002 122.669 26.8002 116.169 22.7002C116.169 23.0002 116.169 23.2002 116.069 23.5002C115.769 24.4002 115.169 25.1002 114.369 25.6002C110.569 27.4002 106.069 28.5002 101.869 28.4002C102.469 29.6002 103.169 31.0002 103.669 32.3002Z" fill="#C4DFD1"/>
<path d="M112.869 22.5998C106.869 13.4998 95.7688 8.89983 92.4688 16.8998C91.1688 25.3998 102.969 27.2998 112.869 22.5998Z" fill="#CBCC2C"/>
<path d="M112.869 22.5998C106.869 13.4998 95.7688 8.89983 92.4688 16.8998C91.1688 25.3998 102.969 27.2998 112.869 22.5998Z" stroke="#231F20" strokeWidth="0.1" strokeMiterlimit="10"/>
<path d="M32.0689 100C33.8689 104.6 36.3689 108.8 39.3689 112.6L129.769 60.4C132.369 66.8 133.869 73.9 133.869 81.3C133.869 111.6 109.369 136.1 79.0689 136.1C61.3689 136.1 45.6689 127.7 35.6689 114.8C32.6689 111 30.2689 106.7 28.3689 102.2L15.6689 109.5C17.6689 114 20.0689 118.2 22.9689 122.1C35.5689 139.4 56.0689 150.7 79.0689 150.7C117.369 150.7 148.469 119.6 148.469 81.3C148.469 71.3 146.369 61.7 142.469 53.1C140.469 48.6 138.069 44.4 135.169 40.5L122.469 47.8L32.0689 100Z" fill="#C4DFD1"/>
<path d="M22.8689 61.7C26.5689 69.5 37.3689 64.4 42.9689 55C32.8689 50.8 21.1689 53.2 22.8689 61.7Z" fill="#CBCC2C"/>
<path d="M22.8689 61.7C26.5689 69.5 37.3689 64.4 42.9689 55C32.8689 50.8 21.1689 53.2 22.8689 61.7Z" stroke="#231F20" strokeWidth="0.1" strokeMiterlimit="10"/>
<path d="M19.8685 79.8998C25.7685 86.1998 34.4685 78.0998 36.9685 67.3998C26.0685 66.4998 15.5684 72.2998 19.8685 79.8998Z" fill="#CBCC2C"/>
<path d="M19.8685 79.8998C25.7685 86.1998 34.4685 78.0998 36.9685 67.3998C26.0685 66.4998 15.5684 72.2998 19.8685 79.8998Z" stroke="#231F20" strokeWidth="0.1" strokeMiterlimit="10"/>
<path d="M52.6691 42.4002C43.1691 37.0002 31.1691 37.9002 31.8691 46.5002C34.5691 54.8002 45.9691 51.1002 52.6691 42.4002Z" fill="#CBCC2C"/>
<path d="M52.6691 42.4002C43.1691 37.0002 31.1691 37.9002 31.8691 46.5002C34.5691 54.8002 45.9691 51.1002 52.6691 42.4002Z" stroke="#231F20" strokeWidth="0.1" strokeMiterlimit="10"/>
<path d="M20.7688 96.2998C27.5688 101.6 34.9688 92.1998 35.7688 81.2998C24.8688 82.0998 15.4688 89.4998 20.7688 96.2998Z" fill="#CBCC2C"/>
<path d="M20.7688 96.2998C27.5688 101.6 34.9688 92.1998 35.7688 81.2998C24.8688 82.0998 15.4688 89.4998 20.7688 96.2998Z" stroke="#231F20" strokeWidth="0.1" strokeMiterlimit="10"/>
<path d="M40.3692 31.1001C48.9692 31.3001 49.2692 19.3001 43.3692 10.1001C35.0692 17.3001 31.9692 28.8001 40.3692 31.1001Z" fill="#CBCC2C"/>
<path d="M40.3692 31.1001C48.9692 31.3001 49.2692 19.3001 43.3692 10.1001C35.0692 17.3001 31.9692 28.8001 40.3692 31.1001Z" stroke="#231F20" strokeWidth="0.1" strokeMiterlimit="10"/>
<path d="M66.0684 19.1C73.9684 22.7 78.8684 11.8 77.0684 1C66.6684 4.3 59.2684 13.8 66.0684 19.1Z" fill="#CBCC2C"/>
<path d="M66.0684 19.1C73.9684 22.7 78.8684 11.8 77.0684 1C66.6684 4.3 59.2684 13.8 66.0684 19.1Z" stroke="#231F20" strokeWidth="0.1" strokeMiterlimit="10"/>
<path d="M17.0687 66.5002C23.6687 61.0002 16.0687 51.8002 5.56873 48.7002C3.96873 59.6002 9.26873 70.3002 17.0687 66.5002Z" fill="#CBCC2C"/>
<path d="M17.0687 66.5002C23.6687 61.0002 16.0687 51.8002 5.56873 48.7002C3.96873 59.6002 9.26873 70.3002 17.0687 66.5002Z" stroke="#231F20" strokeWidth="0.1" strokeMiterlimit="10"/>
<path d="M20.9692 53.7C29.1692 50.9 25.3692 39.6 16.5692 33C11.2692 42.5 12.3692 54.4 20.9692 53.7Z" fill="#CBCC2C"/>
<path d="M20.9692 53.7C29.1692 50.9 25.3692 39.6 16.5692 33C11.2692 42.5 12.3692 54.4 20.9692 53.7Z" stroke="#231F20" strokeWidth="0.1" strokeMiterlimit="10"/>
<path d="M15.9688 96.5C21.2688 89.7 11.8687 82.3 0.96875 81.5C1.76875 92.5 9.16875 101.9 15.9688 96.5Z" fill="#CBCC2C"/>
<path d="M15.9688 96.5C21.2688 89.7 11.8687 82.3 0.96875 81.5C1.76875 92.5 9.16875 101.9 15.9688 96.5Z" stroke="#231F20" strokeWidth="0.1" strokeMiterlimit="10"/>
<path d="M15.2691 81.3999C21.0691 74.9999 12.2691 66.9999 1.36914 65.3999C1.36914 76.2999 8.06913 86.2999 15.2691 81.3999Z" fill="#CBCC2C"/>
<path d="M15.2691 81.3999C21.0691 74.9999 12.2691 66.9999 1.36914 65.3999C1.36914 76.2999 8.06913 86.2999 15.2691 81.3999Z" stroke="#231F20" strokeWidth="0.1" strokeMiterlimit="10"/>
<path d="M53.169 23.4998C61.669 25.0998 63.869 13.2998 59.469 3.2998C50.169 8.9998 45.269 19.9998 53.169 23.4998Z" fill="#CBCC2C"/>
<path d="M53.169 23.4998C61.669 25.0998 63.869 13.2998 59.469 3.2998C50.169 8.9998 45.269 19.9998 53.169 23.4998Z" stroke="#231F20" strokeWidth="0.1" strokeMiterlimit="10"/>
<path d="M29.7687 41.1001C38.0687 38.9001 34.9687 27.3001 26.7687 20.1001C20.8687 29.4001 21.0687 41.4001 29.7687 41.1001Z" fill="#CBCC2C"/>
<path d="M29.7687 41.1001C38.0687 38.9001 34.9687 27.3001 26.7687 20.1001C20.8687 29.4001 21.0687 41.4001 29.7687 41.1001Z" stroke="#231F20" strokeWidth="0.1" strokeMiterlimit="10"/>
<path d="M67.2692 36.2999C60.6692 27.4999 49.3692 23.5999 46.5692 31.7999C45.6692 40.3999 57.6692 41.4999 67.2692 36.2999Z" fill="#CBCC2C"/>
<path d="M67.2692 36.2999C60.6692 27.4999 49.3692 23.5999 46.5692 31.7999C45.6692 40.3999 57.6692 41.4999 67.2692 36.2999Z" stroke="#231F20" strokeWidth="0.1" strokeMiterlimit="10"/>
</svg>

  )
}

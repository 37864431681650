import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import EmailIcon from "../../component/Icons/EmailIcon";
import InstagramIcon from "../../component/Icons/InstagramIcon";
import "./style.css";
import LocationIcon from "../Icons/LocationIcon";
import IconWatch from "../Icons/IconWatch";
import FooterBG from "../Icons/FooterBG";
import api from "../../services/api";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [locationDetails, setLocationDetails] = useState(null); // To store API data  

  const footerRef = useRef(null);

  // Observe the visibility of the footer
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsFooterVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );
    if (footerRef.current) {
      observer.observe(footerRef.current);
    }
    return () => {
      if (footerRef.current) {
        observer.unobserve(footerRef.current);
      }
    };
  }, []);

  // Fetch location details from API
  useEffect(() => {
    const fetchLocationDetails = async () => {
      try {
        const response = await api.post(
          "/getLocation",
          {},
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        console.log("Full API response:", response);

        if (response.data && response.data.data) {
          setLocationDetails(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching location details:", error);
      }
    };

    fetchLocationDetails();
  }, []);

  // Format date and time
  const formatTime = (isoString) => {
    if (!isoString) return "N/A";
    const date = new Date(isoString);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  return (
    <div className="locationcover" ref={footerRef}>
      <section className="section todaylocationsection">
        <div className="titleone text-center">
          <h4>Today Live Location</h4>
        </div>
        <div className="bigscreen">
          <div className="container">
            <div className="livelocation-main">
              <div className="livelocation">
                {/* <iframe
                  src={`https://www.google.com/maps/embed?pb=${locationDetails?.mapEmbedCode || ""}`}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe> */}

                  <iframe
                  src={`https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d29374.80925010389!2d${locationDetails?.Longitude}!3d${locationDetails?.Latitude}00000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1737524661486!5m2!1sen!2sin`}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>

              </div>
              <div className="boxaddresh">
                <h3>Current Availability at the Given Address</h3>

                <div className="contactinfo">
                  <span>
                    <LocationIcon /> Address
                  </span>
                  <p>{locationDetails?.Address || "No address available"}</p>
                </div>

                <div className="contactinfo">
                  <span>
                    <IconWatch /> Time
                  </span>
                  <p>
                    From: {formatTime(locationDetails?.Date_Time_From)} - To:{" "}
                    {formatTime(locationDetails?.Date_Time_To)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="section">
        {isFooterVisible && <FooterBG isVisible={isFooterVisible} />}

        {!isFooterVisible && (
          <div className="footerlocation">
        <a rel="noopener noreferrer" target="_blank"
          title="Live Location"
          href={`https://www.google.com/maps?q=${locationDetails?.Latitude},${locationDetails?.Longitude}`}
          className="locationbtn"
        >
              <p>
                <LocationIcon />
              </p>
              <span>
                From: {formatTime(locationDetails?.Date_Time_From)} - To:{" "}
                {formatTime(locationDetails?.Date_Time_To)}
              </span>
            </a>
          </div>
        )}

        {/* Footer content */}
        <div className="footer-main">
          <div className="container">
            <Link className="logofooter" to="/">
              <img src="../images/logo.svg" alt="Spice on Wheel" />
            </Link>

            <div className="socialmedia">
              <a href="mailto:spiceonwheels@web.de">
                <EmailIcon />
              </a>
              <Link
                to="https://www.instagram.com/spiceonwheels.de"
                target="_blank"
              >
                <InstagramIcon />
              </Link>
            </div>
          </div>
        </div>

        <div className="copyrightfirst">
          <p>© {currentYear} Spice on wheels</p>
          <div className="copyright">
            <div className="container">
              <div className="bottomfooter">
                <p>
                  Design Developed & Managed by{" "}
                  <a
                    href="https://globalsoftwares.net/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>Global Software</strong>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

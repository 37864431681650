import React from "react";

export default function IconLock() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Keyhole">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 3C16 3 18.6442 3 21.0605 4.02201C21.0605 4.02201 23.3936 5.00884 25.1924 6.80761C25.1924 6.80761 26.9912 8.60638 27.978 10.9395C27.978 10.9395 29 13.3558 29 16C29 16 29 18.6442 27.978 21.0605C27.978 21.0605 26.9912 23.3936 25.1924 25.1924C25.1924 25.1924 23.3936 26.9912 21.0605 27.978C21.0605 27.978 18.6442 29 16 29C16 29 13.3558 29 10.9395 27.978C10.9395 27.978 8.60638 26.9912 6.80761 25.1924C6.80761 25.1924 5.00884 23.3936 4.02202 21.0605C4.02202 21.0605 3 18.6442 3 16C3 16 3 13.3558 4.02202 10.9395C4.02202 10.9395 5.00885 8.60638 6.80761 6.80761C6.80761 6.80761 8.60638 5.00884 10.9395 4.02201C10.9395 4.02201 13.3558 3 16 3ZM16 5C16 5 13.7614 5 11.7186 5.86402C11.7186 5.86402 9.74476 6.69889 8.22183 8.22182C8.22183 8.22182 6.6989 9.74476 5.86402 11.7186C5.86402 11.7186 5 13.7614 5 16C5 16 5 18.2386 5.86402 20.2814C5.86402 20.2814 6.69889 22.2552 8.22183 23.7782C8.22183 23.7782 9.74476 25.3011 11.7186 26.136C11.7186 26.136 13.7614 27 16 27C16 27 18.2386 27 20.2814 26.136C20.2814 26.136 22.2552 25.3011 23.7782 23.7782C23.7782 23.7782 25.3011 22.2552 26.136 20.2814C26.136 20.2814 27 18.2386 27 16C27 16 27 13.7614 26.136 11.7186C26.136 11.7186 25.3011 9.74476 23.7782 8.22183C23.7782 8.22183 22.2552 6.69889 20.2814 5.86402C20.2814 5.86402 18.2386 5 16 5Z"
            fill="#1C1C1C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.318 20.2034L20.3191 20.206C20.3191 20.206 20.5181 20.6659 20.4765 21.1658C20.4765 21.1658 20.4347 21.669 20.1589 22.092C20.1589 22.092 19.8832 22.515 19.4398 22.7564C19.4398 22.7564 18.9964 22.9978 18.4874 22.9999L13.5084 22.9999C13.5084 22.9999 13.0035 22.9978 12.5601 22.7564C12.5601 22.7564 12.1166 22.515 11.8409 22.092C11.8409 22.092 11.5652 21.669 11.5233 21.1658C11.5233 21.1658 11.4815 20.6626 11.6808 20.206L13.0828 16.9347C13.0828 16.9347 12.3019 16.2795 11.8749 15.3001C11.8749 15.3001 11.2583 13.8857 11.6393 12.3905C11.6393 12.3905 12.0203 10.8953 13.2386 9.94851C13.2386 9.94851 14.457 9.00171 15.9999 9.00171C15.9999 9.00171 17.5429 9.00171 18.7612 9.94851C18.7612 9.94851 19.9796 10.8953 20.3606 12.3905C20.3606 12.3905 20.7416 13.8857 20.125 15.3001C20.125 15.3001 19.698 16.2794 18.9171 16.9347L20.318 20.2034ZM18.4808 20.9938L16.7558 16.9688C16.5526 16.4946 16.7434 15.9437 17.1964 15.6968C17.1964 15.6968 17.949 15.2866 18.2916 14.5008C18.2916 14.5008 18.6342 13.715 18.4225 12.8844C18.4225 12.8844 18.2108 12.0537 17.534 11.5277C17.534 11.5277 16.8572 11.0017 15.9999 11.0017C15.9999 11.0017 15.1427 11.0017 14.4659 11.5277C14.4659 11.5277 13.789 12.0537 13.5774 12.8844C13.5774 12.8844 13.3657 13.715 13.7083 14.5008C13.7083 14.5008 14.0508 15.2866 14.8035 15.6968C15.2565 15.9437 15.4473 16.4946 15.2441 16.9688L13.5191 20.9938L13.5165 20.9999L18.4834 20.9999L18.4808 20.9938Z"
            fill="#1C1C1C"
          />
        </g>
      </g>
    </svg>
  );
}

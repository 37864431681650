import React, { useEffect, useState } from "react";
import "./style.css";
import { NavLink } from "react-router-dom";
import PhoneMenu from "./PhoneMenu";

export default function Header() {  
  return (
    <header>
      <div className="container">
        
        <PhoneMenu/>

          <nav className="openmenudesktop">
            <ul>
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) => (isActive ? "active" : "")}
                  end
                >
                  Home
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/menu"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Menu
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/gallery"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Gallery
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/location"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Location
                </NavLink>
              </li>
              </ul>
              <div className="logo">
                <NavLink
                  to="/"
                  className={({ isActive }) => (isActive ? "active" : "")}
                  end
                >
                  <img src="../images/logo.svg" alt="Spice on Wheel" />
                </NavLink>
              </div>
              
              <ul>
              <li>
                <NavLink
                  to="/catering"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Catering
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/pre-order"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Pre-Order
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about-us"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact-us"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Contact Us
                </NavLink>
              </li>
              </ul>
            
          </nav>
        </div>
    </header>
  );
}

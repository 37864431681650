import React from "react";

export default function IconMenuClose() {
  return (
    <svg
      fill="none"      
      viewBox="0 0 24 24"      
      xmlns="http://www.w3.org/2000/svg"      
      style={{ cursor: "pointer" }}
    >
      <path
        clipRule="evenodd"
        d="m5.00073 17.5864c-.3905.3906-.39044 1.0237.00012 1.4142s1.02372.3905 1.41421-.0001l5.58524-5.5862 5.5857 5.5857c.3905.3905 1.0237.3905 1.4142 0s.3905-1.0237 0-1.4142l-5.5858-5.5858 5.5854-5.58638c.3904-.39056.3904-1.02372-.0002-1.41421-.3905-.3905-1.0237-.39044-1.4142.00012l-5.5853 5.58627-5.58572-5.58579c-.39052-.39052-1.02369-.39052-1.41421 0-.39053.39053-.39053 1.02369 0 1.41422l5.58593 5.58587z"
        fillRule="evenodd"
      ></path>
    </svg>
  );
}

import React from "react";

export default function IconWatch() {
  return (
    <svg
      height="30"
      viewBox="0 0 512 512"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="m256 0c-140.959 0-256 115.049-256 256 0 140.959 115.049 256 256 256 140.959 0 256-115.05 256-256 0-140.959-115.049-256-256-256zm0 482c-124.617 0-226-101.383-226-226s101.383-226 226-226 226 101.383 226 226-101.383 226-226 226z"></path>
        <circle cx="128.721" cy="383.279" r="15"></circle>
        <circle cx="383.279" cy="128.721" r="15"></circle>
        <path d="m118.114 118.114c-5.858 5.858-5.858 15.355 0 21.213s15.355 5.858 21.213 0 5.858-15.355 0-21.213c-5.857-5.858-15.355-5.858-21.213 0z"></path>
        <path d="m372.673 372.673c-5.858 5.858-5.858 15.355 0 21.213s15.355 5.858 21.213 0 5.858-15.355 0-21.213-15.356-5.858-21.213 0z"></path>
        <path d="m256 121c8.284 0 15-6.716 15-15v-30c0-8.284-6.716-15-15-15s-15 6.716-15 15v30c0 8.284 6.716 15 15 15z"></path>
        <path d="m256 391c-8.284 0-15 6.716-15 15v30c0 8.284 6.716 15 15 15s15-6.716 15-15v-30c0-8.284-6.716-15-15-15z"></path>
        <path d="m121 256c0-8.284-6.716-15-15-15h-30c-8.284 0-15 6.716-15 15s6.716 15 15 15h30c8.284 0 15-6.716 15-15z"></path>
        <path d="m391 256c0 8.284 6.716 15 15 15h30c8.284 0 15-6.716 15-15s-6.716-15-15-15h-30c-8.284 0-15 6.716-15 15z"></path>
        <path d="m271 249.787v-83.787c0-8.284-6.716-15-15-15s-15 6.716-15 15v90c0 3.978 1.581 7.793 4.394 10.607l90 90c5.857 5.857 15.355 5.858 21.213 0s5.858-15.355 0-21.213z"></path>
      </g>
    </svg>
  );
}

import React, { useState, useEffect } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import NavRouting from "./component/NavRouting";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assest/css/admin.css";
import "../src/assest/css/style.css";
import { ToastProvider } from "./services/Toster";
import animationData from "./webloading.json";
import $ from "jquery";
import Lottie from "lottie-react";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      $(".websiteloader").fadeOut(500, () => setLoading(false));
      
      setTimeout(() => {
        setLoading(false);
      }, 500);

    };

    fetchData();
  }, []);
  
  useEffect(() => {
    const handleScrollToTop = (event) => {
      const target = event.target.closest("a");
      if (target) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    };
    document.addEventListener("click", handleScrollToTop);
    return () => {
      document.removeEventListener("click", handleScrollToTop);
    };
  }, []);

  
  return (
    <div className="App">
      <BrowserRouter
        future={{
          v7_relativeSplatPath: true,
          v7_startTransition: true,
        }}
      >
        <ToastProvider>
          <NavRouting loading={loading} />
          {loading && (
            <div className="websiteloader">
              <Lottie
                animationData={animationData}
                loop={true}
                autoplay={true}
              />
            </div>
          )}
        </ToastProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;

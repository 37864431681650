import React from "react";

export default function IconMenuCard() {
  return (
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path d="m51.48047 4h-38.96047a3.08757 3.08757 0 0 0 -3.084 3.084v49.832a3.08757 3.08757 0 0 0 3.084 3.084h38.96047a3.08789 3.08789 0 0 0 3.084-3.084v-49.832a3.08789 3.08789 0 0 0 -3.084-3.084zm1.084 52.916a1.08528 1.08528 0 0 1 -1.084 1.084h-38.96047a1.08528 1.08528 0 0 1 -1.084-1.084v-49.832a1.08528 1.08528 0 0 1 1.084-1.084h38.96047a1.08528 1.08528 0 0 1 1.084 1.084z"></path>
      <path d="m20.499 22.35938a1.00019 1.00019 0 0 0 2-.00007v-7.55267a1.00628 1.00628 0 0 0 -1.80031-.59956l-2.02928 2.708-2.02929-2.708a1.00614 1.00614 0 0 0 -1.80027.59966v7.55269a1 1 0 0 0 2 0v-4.55084l1.0293 1.374a1.03632 1.03632 0 0 0 1.60059 0l1.02929-1.374z"></path>
      <path d="m25.62549 23.35938h4.771a1.00019 1.00019 0 0 0 -.00006-2h-3.77094v-1.77638h3.771a1.00019 1.00019 0 0 0 -.00006-2h-3.77094v-1.77636h3.771a1.00019 1.00019 0 0 0 -.00006-2h-4.77094a.99974.99974 0 0 0 -1 1v7.55274a.99974.99974 0 0 0 1 1z"></path>
      <path d="m34.11523 23.35938a.99942.99942 0 0 0 1-1v-4.23633l3.17677 4.78906a1.00538 1.00538 0 0 0 1.833-.55279v-7.55268a1 1 0 0 0 -2 0v4.23636l-3.17676-4.78906a1.00566 1.00566 0 0 0 -1.833.55279v7.55268a.99942.99942 0 0 0 .99999.99997z"></path>
      <path d="m48.2627 13.80664a.99942.99942 0 0 0 -1 1v6.55274h-3.65918v-6.55274a1.00019 1.00019 0 0 0 -2 .00006v7.55268a.99942.99942 0 0 0 1 1h5.65918a.99943.99943 0 0 0 1-1v-7.55274a.99943.99943 0 0 0 -1-1z"></path>
      <path d="m41.96973 44.39233c-.73877-4.92688-3.45472-8.83239-6.91895-10.26971v-3.04938a2.99954 2.99954 0 0 0 -5.999 0v3.11194c-3.38581 1.481-6.03241 5.33289-6.76685 10.18048a2.42951 2.42951 0 0 0 -2.04126 2.39215v1.00489a2.43438 2.43438 0 0 0 2.43164 2.43164h18.75145a2.43438 2.43438 0 0 0 2.43164-2.43164v-1.00489a2.43231 2.43231 0 0 0 -1.88867-2.36548zm-10.918-13.31909a1.00009 1.00009 0 0 1 1.999.00006v2.50409a7.15281 7.15281 0 0 0 -1.999.0257zm1.06689 4.44531c3.70838 0 6.9419 3.71564 7.82233 8.80762h-15.63187c.8805-5.09198 4.10852-8.80762 7.80957-8.80762zm9.73978 12.24415a.43178.43178 0 0 1 -.43164.43164h-18.75147a.43209.43209 0 0 1 -.43164-.43164c.05684-.43565-.22462-1.42524.43163-1.43652h18.75148c.65833.01182.37394 1.00225.43164 1.43652z"></path>
    </svg>
  );
}
